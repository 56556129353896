import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  Renderer2,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { PageLoadingService } from './page-loading.service';

@Component({
  selector: 'gosu-page-loading',
  template: '',
  styleUrls: ['page-loading.component.scss'],
})
export class PageLoadingComponent implements AfterContentInit, OnDestroy {
  @HostBinding('style.backgroundColor')
  public color: string = '#3498DB';

  @HostBinding('style.height.px')
  public height: string = '7';

  /** progress */
  private _progress: number = 0;

  @Output()
  public progressChange: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  @HostBinding('style.width.%')
  public get progress(): number {
    return this._progress;
  }

  public set progress(progress: number) {
    if (this._progress === progress) return;
    this._progress = progress;
    if (this._progress === 100) this.isComplete = true;
    this.progressChange.emit(this._progress);
  }

  /** complete */
  private _isComplete: boolean;

  @Output()
  public completeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public get isComplete(): boolean {
    return this._isComplete;
  }

  @Input()
  public set isComplete(isComplete: boolean) {
    if (this._isComplete === isComplete) return;
    this._isComplete = isComplete;
    if (this._isComplete === true) this.progress = 100;
    this.completeChange.emit(this._isComplete);
  }

  public complete() {
    this.isComplete = true;
  }

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    public loadingService: PageLoadingService,
    public element: ElementRef,
    public renderer: Renderer2,
  ) {}

  public ngAfterContentInit() {
    if (this.element.nativeElement.id == 'common') {
      this.loadingService.colorChange
        .pipe(takeUntil(this._destroy$))
        .subscribe((color: string) => (this.color = color));

      this.loadingService.progressChange
        .pipe(takeUntil(this._destroy$))
        .subscribe((progress: number) => (this.progress = progress));

      this.loadingService.displayChange
        .pipe(takeUntil(this._destroy$))
        .subscribe((display: boolean) => {
          this.renderer.setStyle(
            this.element.nativeElement,
            'opacity',
            display ? '1' : '0',
          );
          this.renderer.setStyle(
            this.element.nativeElement,
            'filter',
            display ? 'blur(0px)' : 'blur(3px)',
          );
        });
    }
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
