<div
  class="mat-tooltip"
  [ngClass]="tooltipClass"
  [class.mat-tooltip-handset]="(isHandset | async)?.matches"
  [@state]="visibility"
  (@state.start)="animationStart()"
  (@state.done)="animationDone($event)"
>
  <ng-container *ngIf="!isTemplateRef; else tpl">{{message}}</ng-container>

  <ng-template #tpl>
    <ng-container *ngTemplateOutlet="$any(message); context: data">
    </ng-container>
  </ng-template>
</div>
