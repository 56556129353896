import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'fromNow',
  pure: true,
})
export class FromNowPipe implements PipeTransform {
  public transform(value: any): string {
    try {
      if (!value) {
        return '-';
      }
      return moment(value).fromNow();
    } catch (error) {
      console.error($localize`沒有引入 moment，請匯入此 moment cdn。`);
      return value;
    }
  }
}
