import { Pipe, PipeTransform } from '@angular/core';
export type ErrorCorrectionLevel = 'L' | 'M' | 'Q' | 'H';
@Pipe({
  name: 'url2qrcode',
})
export class Url2qrcodePipe implements PipeTransform {
  private _base = 'https://chart.googleapis.com/chart';
  public transform(
    url: string,
    level: ErrorCorrectionLevel = 'L',
    margin: number = 0,
    size: number = 360,
  ): string {
    return `${
      this._base
    }?cht=qr&chld=${level}|${margin}&chs=${size}x${size}&chl=${encodeURIComponent(
      url,
    )}`;
  }
}
