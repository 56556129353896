import { AfterContentInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from '../../service/token.service';

@Component({
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
})
export class LandingPageComponent implements AfterContentInit {
  public constructor(
    private readonly _tokenService: TokenService,
    private readonly _router: Router,
  ) {}

  public async ngAfterContentInit() {
    const isLoggedIn = await this._tokenService.isLoggedIn();
    if (!isLoggedIn) {
      this._router.navigate(['login']);
    }
  }
}
