// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { FacebookPostColumnDto } from './dto/column.dto';

export class FacebookPostModel {
  static getPostsByIds(postIds: string[]): Promise<FacebookPostColumnDto[]> {
    return wsc.execute('/ws/facebook-post/getPostsByIds', postIds) as any;
  }

  static getPosts(
    botIds: number[] = [],
    keyword = '',
    isPublished?: boolean,
    page = 1,
    limit = 10,
  ): Promise<{
    data: FacebookPostColumnDto[];
    page: number;
    limit: number;
    count: number;
  }> {
    return wsc.execute(
      '/ws/facebook-post/getPosts',
      botIds,
      keyword,
      isPublished,
      page,
      limit,
    ) as any;
  }

  static syncPostsByBotIds(botIds: number[]): Promise<any> {
    return wsc.execute('/ws/facebook-post/syncPostsByBotIds', botIds) as any;
  }
}
// 2f67a58c366ad7a036dca3f122cc6478fe58d600714df7ddca64f06e06cdca4a
