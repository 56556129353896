<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container">
    <p i18n>重新命名 <span class="name">{{ name }}</span></p>

    <mat-form-field class="fw">
      <mat-label i18n>新名稱</mat-label>
      <input matInput formControlName="name" />
      <mat-error
        *ngIf="nameInputControl.errors && nameInputControl.errors['required']"
        i18n
      >
        請輸入名稱
      </mat-error>
    </mat-form-field>
  </div>

  <button
    mat-raised-button
    type="submit"
    color="primary"
    [disabled]="form.invalid"
    i18n
  >
    確定
  </button>
</form>
