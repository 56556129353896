import { Pipe, PipeTransform } from '@angular/core';
import en from '../../util/translate-map/en.json';

@Pipe({
  name: 'translate',
  pure: true,
})
export class TranslatePipe implements PipeTransform {
  public transform(input: string): string {
    if ($localize.locale == 'en') {
      return en.hasOwnProperty(input) ? en[input] : input;
    }
    return input;
  }
}
