import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dl-error-dialog',
  templateUrl: './error.dialog.html',
  styleUrls: ['./error.dialog.scss'],
})
export class ErrorDialog {
  @Input() public content: string;

  @Input() public buttons: {
    label: string;
    type?: string;
    color?: string;
    result?: string;
  }[] = [{ label: 'OK', type: 'raised', color: 'primary', result: 'OK' }];

  public constructor(private readonly _matDialogRef: MatDialogRef<any>) {}

  public close(result: string) {
    this._matDialogRef.close(result);
  }
}
