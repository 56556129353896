<ng-container *ngIf="isFaIcon; else notFaIcon">
  <fa-icon [icon]="faIcons[icon]"></fa-icon>
</ng-container>

<ng-template #notFaIcon>
  <ng-container *ngIf="isSvgFile; else notSvgFile">
    <div class="inline-svg" [inlineSVG]="icon"></div>
  </ng-container>
</ng-template>

<ng-template #notSvgFile>
  <mat-icon> {{ icon }} </mat-icon>
</ng-template>
