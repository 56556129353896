// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { SchedulerPageListDto } from './dto/page-list.dto';
import { SchedulerJobDto } from './dto/schedule-job.dto';

export class SchedulerModel {
  static list(
    params?: {
      type?: 'ONCE' | 'EVERY';
      gosuAccountId?: number;
      gosuCompanyId?: number;
      gosuBotId?: number;
      gosuProfileId?: number;
      gosuPackageId?: number;
      gosuFlowId?: number;
      gosuNodeId?: number;
      title?: string;
      showExpired?: boolean;
      filter?: string;
    },
    page = 1,
    limit = 50,
  ): Promise<SchedulerPageListDto<SchedulerJobDto>> {
    return wsc.execute('/ws/scheduler/list', params, page, limit) as any;
  }

  static deleteByNodeId(nodeId: number): Promise<any> {
    return wsc.execute('/ws/scheduler/deleteByNodeId', nodeId) as any;
  }

  static deleteByNodeIdAndProfileId(
    nodeId: number,
    profileId: number,
  ): Promise<any> {
    return wsc.execute(
      '/ws/scheduler/deleteByNodeIdAndProfileId',
      nodeId,
      profileId,
    ) as any;
  }

  static deleteByJobId(jobId: number): Promise<any> {
    return wsc.execute('/ws/scheduler/deleteByJobId', jobId) as any;
  }
}
// 210ea29b504193aa46f5a2ab0e8360a79d4ffc56cdeb23a605160ff0ebfd4eb1
