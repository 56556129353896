// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';
import { Logger } from './dto/logger.dto';

export class LogModel {
  static setReadingTime(profileId: number): Promise<boolean> {
    return wsc.execute('/ws/log/setReadingTime', profileId) as any;
  }

  static listenReadingTime(): Observable<{ profileId: number }> {
    return wsc.subscribe('/ws/log/listenReadingTime') as any;
  }

  static fetchLog(
    page: number,
    pageSize: number,
    _where: {
      botId?: number | number[];
      profileId?: number | number[];
      accountId?: number | number[];
      flowId?: number | number[];
      nodeId?: number | number[];
    },
  ): Promise<Logger> {
    return wsc.execute('/ws/log/fetchLog', page, pageSize, _where) as any;
  }

  static monitor(profileId: number): Observable<any> {
    return wsc.subscribe('/ws/log/monitor', profileId) as any;
  }
}
// 8f0c8012f0c9d2c1513774232bea71445797e8631836260795339df4233016ee
