<button
  class="menu"
  mat-icon-button
  (click)="iconClick.emit($event)"
  *ngIf="(logged$ | async) && !!(contract$ | async)"
>
  <mat-icon class="x24">menu</mat-icon>
</button>

<gosu-logo [size]="164" theme="primary"></gosu-logo>

<div class="blank"></div>

<ng-container *ngIf="announcement$ | async as announcement">
  <ng-container *ngIf="announcement.link; else notLink">
    <a
      class="announcement"
      [style.backgroundColor]="announcement.background_color"
      [style.color]="announcement.font_color"
      [href]="announcement.link || '#'"
      target="_new"
    >
      <ng-container
        *ngTemplateOutlet="inner; context: { $implicit: announcement }"
      >
      </ng-container>
    </a>
  </ng-container>

  <ng-template #notLink>
    <div
      class="announcement"
      [style.backgroundColor]="announcement.background_color"
      [style.color]="announcement.font_color"
      [matTooltip]="announcement.title"
    >
      <ng-container
        *ngTemplateOutlet="inner; context: { $implicit: announcement }"
      >
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<div class="blank"></div>

<div class="payment-button-wrap" *ngIf="environment.features.payment">
  <button
    class="payment-button"
    mat-flat-button
    color="primary"
    routerLink="/payment"
    i18n
  >
    <mat-icon>shopping_cart</mat-icon>
    前往購買
  </button>
</div>

<button
  mat-icon-button
  element-ref
  #elementRef="elementRef"
  class="notification-button"
  *ngIf="showNotifications$ | async"
  (click)="toggleActivityRecord(elementRef)"
>
  <mat-icon>notifications</mat-icon>
</button>

<ng-container *ngIf="companyService.company$ | async; let company">
  <div class="company-button-wrap">
    <button
      class="company-button"
      mat-button
      [matMenuTriggerFor]="companyMenu"
      #companyMenuTrigger="matMenuTrigger"
      fxLayout="row"
      fxLayoutAlign="center center"
      [class.no-contract]="(contract$ | async) === null"
    >
      <div class="name" fxFlex="1 0 0">{{ company.name }}</div>
      <div class="icon-wrap">
        <mat-icon>keyboard_arrow_down</mat-icon>
      </div>
    </button>
  </div>

  <mat-menu #companyMenu="matMenu" class="company-menu">
    <div
      class="contract"
      fxLayout="row"
      fxLayoutAlign="left center"
      *ngIf="contract$ | async; let contract; else: noContract"
    >
      <mat-icon>beenhere</mat-icon>

      <div class="right">
        <div class="name">{{ contract?.plan?.name }}</div>
        <div class="date">
          <ng-container *ngIf="contract.end; else noEnd">
            {{ contract.start }} ~ {{ contract.end }}
          </ng-container>
          <ng-template #noEnd i18n> {{ contract.start }} 起 </ng-template>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <ng-template #noContract>
      <div class="contract empty" fxLayout="row" fxLayoutAlign="left center">
        <mat-icon>beenhere</mat-icon>
        <div class="right">
          <div class="name" i18n>沒有任何方案</div>
        </div>
      </div>
    </ng-template>

    <button
      mat-menu-item
      routerLink="/company/settings"
      *ngIf="environment.features.companyInfo"
      i18n
    >
      <mat-icon>settings</mat-icon> 組織設定
    </button>

    <button
      mat-menu-item
      routerLink="/market-place"
      *ngIf="environment.features.payment"
      i18n
    >
      <mat-icon>money</mat-icon> 帳務管理
    </button>
    <button
      mat-menu-item
      element-ref
      #elementRef="elementRef"
      (click)="selectCompany(elementRef, $event)"
      *ngIf="environment.features.changeCompany"
      i18n
    >
      <mat-icon>compare_arrows</mat-icon> 切換組織
    </button>
  </mat-menu>
</ng-container>

<div class="account-button-wrap">
  <button
    class="account-button"
    *ngIf="tokenService.account$ | async; let account"
    mat-button
    [matMenuTriggerFor]="accountMenu"
    fxLayout="row"
    fxLayoutAlign="center center"
  >
    <div class="name" fxFlex="1 0 0">{{ account.name }}</div>
    <div class="icon-wrap">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </div>
  </button>
</div>

<mat-menu #accountMenu="matMenu" class="account-menu">
  <ng-container *ngIf="tokenService.account$ | async; let account">
    <ng-container *ngIf="account.roles && account.roles.length">
      <button mat-menu-item disabled="true" *ngFor="let role of account.roles">
        <mat-icon>badge</mat-icon> {{ role }}
      </button>
    </ng-container>
  </ng-container>

  <button
    mat-menu-item
    routerLink="/account/personal"
    *ngIf="environment.features.changePassword"
    i18n
  >
    <mat-icon>settings</mat-icon> 帳號設定
  </button>

  <button mat-menu-item (click)="logout()" i18n>
    <mat-icon>exit_to_app</mat-icon> 登出
  </button>
</mat-menu>

<ng-template #inner let-announcement>
  <mat-icon [style.color]="announcement!.font_color" *ngIf="announcement!.icon">
    {{ announcement!.icon }}
  </mat-icon>
  {{ announcement!.title }}
</ng-template>

<ng-template #noContractDialog>
  <div
    class="no-contract-dialog"
    *ngIf="companyService.company$ | async; let company"
  >
    <gosu-logo [size]="120" theme="dark"></gosu-logo>
    <h3 i18n>免費試用期已結束</h3>
    <div class="flex">
      <div class="left" i18n>
        <p>親愛的用戶 您好：</p>
        <p>
          組織「{{ company.name }}」的免費試用期已結束，若您希望繼續使用 GOSU
          BAR 的服務，請您前往購買，完成購買後即可重新使用。
        </p>

        <p>
          提醒您，我們提供了30天的帳號保留期，如您未於此期間內
          <span class="red">
            {{ keep.start | moment: 'yyyy年MM月dd日' }}
            ～
            {{ keep.end | moment: 'yyyy年MM月dd日' }}
          </span>
          進行購買，組織「
          {{ company.name }}
          」將會被刪除，您所建立的流程、圖像、標籤、屬性等內容也會一併被清空。
        </p>

        <p>以上，感謝您對 GOSU BAR 的支持。</p>
        <p>
          <button
            mat-flat-button
            color="primary"
            routerLink="/payment"
            (click)="matConnectedDialog.closeAll()"
          >
            前往購買
          </button>
        </p>
      </div>
      <div class="right">
        <img
          src="../../../assets/market-place/icon_expired.svg"
          alt="免費試用期已結束"
          i18n-alt="免費試用期已結束"
        />
        <p i18n>完成付款，即刻使用</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #expiredDialog>
  <div
    class="no-contract-dialog"
    *ngIf="companyService.company$ | async; let company"
  >
    <gosu-logo [size]="120" theme="dark"></gosu-logo>
    <ng-container *ngIf="lastContract$ | async; let contract">
      <h3 i18n>免費試用期已結束</h3>
      <div class="flex">
        <div class="left">
          <p i18n>親愛的用戶 您好：</p>
          <p i18n>
            <span>組織「{{ company.name }}」的免費試用期</span>，
            <span>
              已於
              {{ contract.end || today | date: 'yyyy 年 MM 月 dd 日' }} 結束，
            </span>

            <span>若您希望繼續使用 GOSU BAR 的服務，</span>
            <span>請您前往購買，</span>
            <span>完成購買後即可重新使用。</span>
          </p>

          <p i18n>
            <span>提醒您，</span><span>我們提供了30天的帳號保留期，</span>
            <span> 如您未於此期間內 </span>
            <span class="red">
              {{ keep.start | moment: 'yyyy年MM月dd日' }}
              ～
              {{ keep.end | moment: 'yyyy年MM月dd日' }}
            </span>
            <span>進行購買，</span>
            <span>組織「 {{ company.name }} 」將會被刪除，</span>
            <span>您所建立的流程、圖像、標籤、屬性等內容也會一併被清空。</span>
          </p>

          <p i18n>以上，感謝您對 GOSU BAR 的支持。</p>
          <p i18n>
            <button
              mat-flat-button
              color="primary"
              routerLink="/payment"
              (click)="matConnectedDialog.closeAll()"
            >
              前往購買
            </button>
          </p>
        </div>
        <div class="right">
          <img
            src="../../../assets/market-place/icon_expired.svg"
            alt="免費試用期已結束"
            i18n-alt="免費試用期已結束"
          />
          <p i18n>完成付款，即刻使用</p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>
