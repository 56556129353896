// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { ExchangeListResponse } from './dto/list-response.dto';

export class ExchangeModel {
  static create(data: any): Promise<number> {
    return wsc.execute('/ws/exchange/create', data) as any;
  }

  static list(size = 30, page = 1): Promise<ExchangeListResponse> {
    return wsc.execute('/ws/exchange/list', size, page) as any;
  }

  static fetch(id: number): Promise<any> {
    return wsc.execute('/ws/exchange/fetch', id) as any;
  }

  static update(id: number, data: any): Promise<void> {
    return wsc.execute('/ws/exchange/update', id, data) as any;
  }

  static delete(id: number): Promise<void> {
    return wsc.execute('/ws/exchange/delete', id) as any;
  }
}
// 5e10f500e6a2c6a447af647a62c10a3dafaad6fffd3d282252822303c708041f
