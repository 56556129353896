import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { NgxMonacoEditorConfig, NGX_MONACO_EDITOR_CONFIG } from './config';
import { EditorComponent } from './editor.component';
import { MonacoEditorLoaderService } from './monaco-editor-loader.service';

@NgModule({
  imports: [CommonModule],
  declarations: [EditorComponent],
  exports: [EditorComponent],
})
export class MonacoEditorModule {
  public static forRoot(
    config: NgxMonacoEditorConfig = {},
  ): ModuleWithProviders<MonacoEditorModule> {
    return {
      ngModule: MonacoEditorModule,
      providers: [
        { provide: NGX_MONACO_EDITOR_CONFIG, useValue: config },
        MonacoEditorLoaderService,
      ],
    };
  }
}
