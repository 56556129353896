// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { ProfileDto } from '../_module/dto/profile.type';
import { wsc } from '../wsc';
import { Observable } from 'rxjs';
import { AddTagByPropertyResponseDto } from './dto/add-tag-by-property-response.dto';
import { FindConditionDto } from './dto/find-condition.dto';
import { GetPrivateMessageListDto } from './dto/get-private-message-list.dto';
import { MessageBox } from './dto/message-box.dto';

export class ProfileModel {
  static backfillProperty(): Promise<any> {
    return wsc.execute('/ws/profile/backfillProperty') as any;
  }

  /** 搜尋 Profile */
  static find(
    /** 過濾條件 */
    condition?: FindConditionDto,
    // 分頁
    paging?:
      | {
          /** 現在頁數，從1開始，預設1 */
          current?: number;
          /** 每頁幾筆，預設100 */
          count?: number;
        }
      | false,
    // 包含詳細資料
    detail?: {
      /** 包含使用者基本資料 */
      info?: boolean;
      /** 標籤，預設false */
      tag?: boolean;
      /** 屬性，預設false */
      property?: boolean;
    },
  ): Promise<ProfileDto[]> {
    return wsc.execute('/ws/profile/find', condition, paging, detail) as any;
  }

  static getPrivateMessage(profileId: number): Promise<MessageBox> {
    return wsc.execute('/ws/profile/getPrivateMessage', profileId) as any;
  }

  static getPrivateMessageList(data: GetPrivateMessageListDto): Promise<{
    frame: number;
    page: number;
    data: MessageBox[];
    total: number;
  }> {
    return wsc.execute('/ws/profile/getPrivateMessageList', data) as any;
  }

  /** 設定 Profile 身上的 Property */
  static setProperty(
    /** 使用者編號 */
    id: number,
    /** 屬性名 */
    key: string,
    /** 值 */
    value = '',
    /** 異動原因 */
    reason = '',
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/profile/setProperty',
      id,
      key,
      value,
      reason,
    ) as any;
  }

  /** 設定 Profile 身上的 Tag */
  static addTag(
    /** 使用者編號 */
    id: number,
    /** tag編號 */
    tagIds: number | number[],
  ): Promise<boolean> {
    return wsc.execute('/ws/profile/addTag', id, tagIds) as any;
  }

  /** 刪除 Profile 身上的 Tag */
  static removeTag(
    /** 使用者編號 */
    id: number,
    /** tag編號 */
    tagIds: number | number[],
  ): Promise<boolean> {
    return wsc.execute('/ws/profile/removeTag', id, tagIds) as any;
  }

  static getTags(id: number): Promise<number[]> {
    return wsc.execute('/ws/profile/getTags', id) as any;
  }

  static hasTag(id: number, tagId: number): Promise<boolean> {
    return wsc.execute('/ws/profile/hasTag', id, tagId) as any;
  }

  static getProperties(id: number): Promise<{ [key: string]: string }> {
    return wsc.execute('/ws/profile/getProperties', id) as any;
  }

  static getProperty(id: number, key: string): Promise<any> {
    return wsc.execute('/ws/profile/getProperty', id, key) as any;
  }

  static removeProperty(id: number, key: string): Promise<boolean> {
    return wsc.execute('/ws/profile/removeProperty', id, key) as any;
  }

  static leave(profileId: number): Promise<any> {
    return wsc.execute('/ws/profile/leave', profileId) as any;
  }

  static updateTagIds(profileId: number, destination: number[]): Promise<any> {
    return wsc.execute(
      '/ws/profile/updateTagIds',
      profileId,
      destination,
    ) as any;
  }

  static fetchTagsProfileCount(tagId: number): Promise<number> {
    return wsc.execute('/ws/profile/fetchTagsProfileCount', tagId) as any;
  }

  static fetchMembers(
    id: number,
  ): Promise<{ id: number; name: string; picture: string }[]> {
    return wsc.execute('/ws/profile/fetchMembers', id) as any;
  }

  /** 批次貼上擁有特定屬性 Profile 的 Tag */
  static addTagByProperty(
    /** 屬性名稱 */
    key: string,
    /** 屬性值 */
    values: string[],
    /** tag編號 */
    tagIds: number[],
  ): Observable<AddTagByPropertyResponseDto> {
    return wsc.subscribe(
      '/ws/profile/addTagByProperty',
      key,
      values,
      tagIds,
    ) as any;
  }
}
// 3a17f00ff4a1e0cce018d3bd90c30139863e899c6e3f3ceb5943ff3e137c78aa
