import { FormControl } from '@angular/forms';

export type ComparisonStringOption = {
  ignoreCase?: boolean;
};

export function comparisonString(
  control: FormControl,
  compared: string,
  option?: ComparisonStringOption,
) {
  let ignoreCase = option && option.ignoreCase;
  let value: string = control.value;

  if (ignoreCase) {
    value = (value + '').toUpperCase();
    compared = (compared + '').toUpperCase();
  }

  if (value !== compared) {
    return { 'comparison-string': true };
  }

  return null;
}
