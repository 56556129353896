// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { PageColumn } from './dto/page.dto';

export class PageModel {
  static list(): Promise<PageColumn[]> {
    return wsc.execute('/ws/page/list') as any;
  }
}
// cd9783cf6417f2a19dd7f0efb2a222c03867daa07087fc971ad4c838677e6a11
