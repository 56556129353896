// dev
export const environment = {
  ENV: 'local',
  production: false,
  serverUrl: ['https://d2-api.gosu.bar'],
  webhookUrl: 'https://[botId].d2-api.gosu.bar/[platform]',
  customizeUrl: 'https://[botId].d2-api.gosu.bar/customize/[event]',
  surveyCakeUrl: 'https://[botId].d2-api.gosu.bar/SurveyCake/fill/[svid]',
  surveyCakeThankPageUrl:
    'https://[botId].d2-api.gosu.bar/SurveyCake/thank/[svid]',
  callbackUrl: 'https://[botId].d2-api.gosu.bar/oauth/line/logged',
  profileUrl: 'https://d2-api.gosu.bar/profile',
  richmenuUrl: 'https://d2-api.gosu.bar/richmenu',
  couponUrl: 'https://d2-api.gosu.bar/coupon',
  sharePromotionChannelUrl: 'https://[botId].d2-api.gosu.bar/share/[key]',
  addPromotionChannelUrl: 'https://[botId].d2-api.gosu.bar/add/[key]/[subKey]',
  loginTokenKey: 'daaWJszhLVXbzBRTQkwgQBGQdmkEeaWvFD',
  noPassword: true,
  gtmId: 'GTM-NSSL8WV',
  features: {
    homePage: true,
    changePassword: true,
    marketPlace: true,
    payment: true,
    changeCompany: true,
    companyInfo: true,
    feverSocial: true,
  },
  LineConfig: {
    enable: true,
  },
  FacebookConfig: {
    enable: true,
    appId: '336580980238492',
    cookie: true,
    xfbml: true,
    version: 'v8.0',
  },
  GoogleConfig: {
    enable: true,
    clientId:
      '364312937867-o4i8l80b1b24olooiuj2ff7ce762grla.apps.googleusercontent.com',
    apiKey: 'ihSVn57RHmiZzxW1Qt4AKHJv',
    TranslateAPIKey: 'AIzaSyAUIx1EVr7LOPinv0hTJkAt1htvbUlMCAI',
  },
  partner: 'https://d-partner.gosu.bar/',
  sentry: 'https://ba3e2a2d475a45209e0bc875c1a0ae2c@sentry.anyong.com.tw/2',
  directus: {
    url: 'https://directus.gosu.bar',
    project: '2',
  },
  flexTemplates: [],
};

console.log('ENV:', environment.ENV);
