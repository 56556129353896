<div
  class="user-info"
  *ngIf="!dialogData && tokenService.accountWithoutCompany$ | async as account"
>
  <div fxLayout="row" fxLayoutAlign="center center">
    <h1 fxFlex="1 0 0">{{ account.name }}</h1>

    <div class="wrap">
      <button mat-icon-button class="logout" (click)="tokenService.logout()">
        <mat-icon>exit_to_app</mat-icon>
      </button>
    </div>
  </div>
</div>

<mat-label i18n>選擇組織</mat-label>
<mat-list>
  <ng-container *ngIf="companies$ | async as companies">
    <mat-list-item *ngFor="let company of companies">
      <button mat-button (click)="selectCompany(company)">
        <div class="wrap" fxLayout="row" fxLayoutAlign="center center">
          <div class="name" fxFlex="1 0 0">{{ company.name }}</div>
          <div class="ext">
            <div class="id">#{{ company.id }}</div>
            <p class="created-at" i18n>{{ company.createdAt | fromNow }}建立</p>
          </div>
        </div>
      </button>
    </mat-list-item>
  </ng-container>
</mat-list>

<button
  mat-button
  color="primary"
  class="create"
  (click)="createCompany()"
  i18n
>
  <mat-icon>add</mat-icon>
  建立組織
</button>
