// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { PrivateMessage } from '@ay/bot';

export class RecordModel {
  static history(profileId: number, page = 1): Promise<PrivateMessage[]> {
    return wsc.execute('/ws/record/history', profileId, page) as any;
  }
}
// 025d3770a02f0da6e7258116fbad97cc54d30928be9d0767128074ad2a941542
