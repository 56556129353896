import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split',
  pure: true,
})
export class SplitPipe implements PipeTransform {
  public transform(input: string, keyword = ','): string[] {
    if (typeof input != 'string') return [];
    return input.split(keyword).filter((s) => !!s);
  }
}
