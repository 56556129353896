import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PageLoadingComponent } from './page-loading.component';
import { PageLoadingService } from './page-loading.service';

@NgModule({
  imports: [CommonModule, FormsModule],
  declarations: [PageLoadingComponent],
  providers: [PageLoadingService],
  exports: [PageLoadingComponent],
})
export class PageLoadingModule {}
