import { Directive, ElementRef, NgModule } from '@angular/core';

@Directive({
  selector: '[element-ref], [elementRef]',
  exportAs: 'elementRef',
})
export class ElementRefDirective<T = any> extends ElementRef<T> {
  public constructor(protected elementRef: ElementRef<T>) {
    super(elementRef.nativeElement);
  }
}

@NgModule({
  declarations: [ElementRefDirective],
  exports: [ElementRefDirective],
})
export class ElementRefModule {}
