import { Injectable } from '@angular/core';
import { AccountFetchOverviewDto, AccountModel } from '@ay-gosu/server-shared';
import { arrayToObject } from '@ay/util';
import { firstValueFrom } from 'rxjs';
import { first, map, shareReplay } from 'rxjs/operators';
import { PreloadService } from './preload.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService extends PreloadService<AccountFetchOverviewDto> {
  public map$ = this.all$.pipe(
    map((all) => arrayToObject(all, 'id')),
    shareReplay(1),
  );

  protected load(): Promise<AccountFetchOverviewDto[]> {
    return AccountModel.getOverview();
  }

  protected async get(id: number): Promise<AccountFetchOverviewDto> {
    let accounts = await firstValueFrom(
      this.all$.pipe(first((account) => account !== null)),
    );

    return accounts.find((account) => account.id === id);
  }

  public constructor(protected _tokenService: TokenService) {
    super(_tokenService);
  }

  public async fetchName(accountId: number) {
    if (!accountId) return '';
    const accounts = await firstValueFrom(this.all$);
    const account = accounts.find((account) => account.id === accountId);
    if (!account) return '';
    return account.name;
  }
}
