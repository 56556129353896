import { ArrayDTS } from './array';
import { BooleanDTS } from './boolean';
import { DateDTS } from './date';
import { ErrorDTS } from './error';
import { FunctionDTS } from './function';
import { JsonDTS } from './json';
import { MathDTS } from './math';
import { MomentDTS } from './moment';
import { NumberDTS } from './number';
import { ObjectDTS } from './object';
import { PromiseDTS } from './promise';
import { RegExpDTS } from './regexp';
import { StringDTS } from './string';
import { UtilDTS } from './util';

export function onMonacoLoad() {
  const monaco = (window as any).monaco;
  const ts = monaco.languages.typescript.typescriptDefaults;

  ts.setCompilerOptions({
    noLib: true,
    allowNonTsExtensions: true,
  });

  ts.addExtraLib(ArrayDTS, 'Array.d.ts');
  ts.addExtraLib(BooleanDTS, 'Boolean.d.ts');
  ts.addExtraLib(DateDTS, 'Date.d.ts');
  ts.addExtraLib(ErrorDTS, 'Error.d.ts');
  ts.addExtraLib(FunctionDTS, 'Function.d.ts');
  ts.addExtraLib(JsonDTS, 'JSON.d.ts');
  ts.addExtraLib(MathDTS, 'Math.d.ts');
  ts.addExtraLib(MomentDTS, 'Moment.d.ts');
  ts.addExtraLib(NumberDTS, 'Number.d.ts');
  ts.addExtraLib(ObjectDTS, 'Object.d.ts');
  ts.addExtraLib(PromiseDTS, 'Promise.d.ts');
  ts.addExtraLib(RegExpDTS, 'RegExp.d.ts');
  ts.addExtraLib(StringDTS, 'String.d.ts');
  ts.addExtraLib(UtilDTS, 'Util.d.ts');
}

export var monacoConfig = {
  baseUrl: './assets',
  defaultOptions: {
    scrollBeyondLastLine: false,
    minimap: { enabled: false },
  },
  onMonacoLoad,
};
