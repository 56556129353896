// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { CouponDto } from './dto/coupon.dto';
import { CouponGetActivityReportDto } from './dto/get-activity-report.dto';
import { CouponGetOverviewReportDto } from './dto/get-overview-report.dto';
import { CouponGetViaReportDto } from './dto/get-via-report.dto';
import { CouponViaType } from './dto/via-type.type';

export class CouponModel {
  static get(id: number): Promise<CouponDto> {
    return wsc.execute('/ws/coupon/get', id) as any;
  }

  static list(): Promise<CouponDto[]> {
    return wsc.execute('/ws/coupon/list') as any;
  }

  static create(data: CouponDto): Promise<CouponDto> {
    return wsc.execute('/ws/coupon/create', data) as any;
  }

  static update(data: CouponDto): Promise<boolean> {
    return wsc.execute('/ws/coupon/update', data) as any;
  }

  static publish(id: number, time?: Date): Promise<boolean> {
    return wsc.execute('/ws/coupon/publish', id, time) as any;
  }

  static cancelPublish(id: number): Promise<boolean> {
    return wsc.execute('/ws/coupon/cancelPublish', id) as any;
  }

  static getHashId(
    botId: number,
    couponId: number,
  ): Promise<{ hashedBotId: string; hashedCouponId: string }> {
    return wsc.execute('/ws/coupon/getHashId', botId, couponId) as any;
  }

  static delete(id: number): Promise<boolean> {
    return wsc.execute('/ws/coupon/delete', id) as any;
  }

  static getOverviewReport(id: number): Promise<CouponGetOverviewReportDto> {
    return wsc.execute('/ws/coupon/getOverviewReport', id) as any;
  }

  static getCouponViaReport(
    couponId: number,
    onlyUsed = false,
  ): Promise<CouponGetViaReportDto[]> {
    return wsc.execute(
      '/ws/coupon/getCouponViaReport',
      couponId,
      onlyUsed,
    ) as any;
  }

  static getCouponViaTopInfo(
    couponId: number,
    viaType: CouponViaType,
    onlyUsed = false,
  ): Promise<any> {
    return wsc.execute(
      '/ws/coupon/getCouponViaTopInfo',
      couponId,
      viaType,
      onlyUsed,
    ) as any;
  }

  static getCouponActivityReport(
    couponId: number,
  ): Promise<CouponGetActivityReportDto[]> {
    return wsc.execute('/ws/coupon/getCouponActivityReport', couponId) as any;
  }

  static recalculateCouponReport(couponId: number): Promise<void> {
    return wsc.execute('/ws/coupon/recalculateCouponReport', couponId) as any;
  }

  static outputDetail(couponId: number): Promise<string> {
    return wsc.execute('/ws/coupon/outputDetail', couponId) as any;
  }

  static fetchCouponListByProfileId(profileId: number): Promise<any[]> {
    return wsc.execute(
      '/ws/coupon/fetchCouponListByProfileId',
      profileId,
    ) as any;
  }
}
// 530364b03e3ffcb41359b00fa7a17d8b08331be6838ac54d4fce485bcd30f490
