import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateModeType',
  pure: true,
})
export class DateModeTypePipe implements PipeTransform {
  public transform(input: string): string {
    switch (input) {
      case 'datetime':
        return $localize`日期與時間`;
      case 'date':
        return $localize`日期`;
      case 'time':
        return $localize`時間`;
      default:
        return input;
    }
  }
}
