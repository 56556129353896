import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'moduleType',
  pure: true,
})
export class ModuleTypePipe implements PipeTransform {
  public transform(input: string): string {
    if (typeof input != 'string') return input;
    switch (input.toLowerCase()) {
      case 'profile':
        return $localize`好友`;

      case 'package':
        return $localize`訊息包`;

      case 'bot':
        return $localize`機器人`;

      case 'company':
        return $localize`組織`;

      default:
        return input;
    }
  }
}
