// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { AuthStrType } from './dto/auth-str-type.type';
import { AuthType } from './dto/auth-type.type';
import { AuthColumn } from './dto/auth.dto';

export class AuthModel {
  static getPageAuthList(accountId: number): Promise<AuthColumn[]> {
    return wsc.execute('/ws/auth/getPageAuthList', accountId) as any;
  }

  static setPageAuth(
    accountId: number,
    pageId: number,
    auths: AuthStrType | AuthStrType[] = [],
  ): Promise<boolean> {
    return wsc.execute('/ws/auth/setPageAuth', accountId, pageId, auths) as any;
  }

  static removePageAuth(accountId: number, pageId: number): Promise<boolean> {
    return wsc.execute('/ws/auth/removePageAuth', accountId, pageId) as any;
  }

  static getAuthList(accountId: number, type: AuthType): Promise<AuthColumn[]> {
    return wsc.execute('/ws/auth/getAuthList', accountId, type) as any;
  }
}
// 70e8ca52b04fc5a35205d50d201ee3a3959874a82d909a3e4362f029f5e1828d
