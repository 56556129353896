import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GosuIconModule } from '../../components/gosu-icon';
import { GosuLogoModule } from '../../components/gosu-logo';
import { LandingPageComponent } from './landing.page';

@NgModule({
  imports: [GosuIconModule, GosuLogoModule, RouterModule],
  declarations: [LandingPageComponent],
})
export class LandingPageModule {}
