<h1 i18n>組織設定</h1>

<mat-accordion multi>
  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title i18n> 基本設定 </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="companyForm">
      <mat-form-field>
        <mat-label i18n>組織編號</mat-label>
        <input matInput formControlName="id" />
      </mat-form-field>
      <mat-form-field>
        <mat-label i18n>組織名稱</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>
    </form>
  </mat-expansion-panel>

  <mat-expansion-panel expanded="true">
    <mat-expansion-panel-header>
      <mat-panel-title i18n> 屬性設定 </mat-panel-title>
    </mat-expansion-panel-header>
    <form [formGroup]="propertyForm">
      <mat-form-field *ngFor="let config of propertyConfigs">
        <mat-label>{{ config.name }} {{ config.key }}</mat-label>
        <input matInput [formControlName]="config.key" />
      </mat-form-field>
    </form>
  </mat-expansion-panel>
</mat-accordion>

<mat-divider></mat-divider>

<div class="submit-wrap">
  <button
    class="submit"
    mat-raised-button
    color="primary"
    [disabled]="companyForm.invalid || propertyForm.invalid || !!loadPercent"
    (click)="sendChange()"
    i18n
  >
    確定修改
  </button>
</div>

<mat-progress-bar mode="indeterminate" *ngIf="loadPercent"> </mat-progress-bar>
