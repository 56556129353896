<div fxLayout="row" class="container">
  <div class="info">
    <mat-icon>info</mat-icon>
  </div>

  <p [innerHTML]="content"></p>
</div>

<div class="button-wrap">
  <ng-container *ngFor="let button of buttons">
    <ng-container [ngSwitch]="button.type">
      <ng-template ngSwitchCase="raised">
        <button
          mat-raised-button
          (click)="close(button.result)"
          [color]="button.color"
        >
          {{ button.label }}
        </button>
      </ng-template>

      <ng-template ngSwitchDefault>
        <button
          mat-button
          (click)="close(button.result)"
          [color]="button.color"
        >
          {{ button.label }}
        </button>
      </ng-template>
    </ng-container>
  </ng-container>
</div>
