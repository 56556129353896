import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatConnectedDialogModule } from '../../material/connected-dialog';
import { ConfirmDialog } from './confirm/confirm.dialog';
import { CopyConfirmDialog } from './copy-confirm/copy-confirm.dialog';
import { DeleteConfirmDialog } from './delete-confirm/delete-confirm.dialog';
import { ErrorDialog } from './error/error.dialog';
import { InfoDialog } from './info/info.dialog';
import { LoadingDialog } from './loading/loading.dialog';
import { PermissionDeniedDialog } from './permission-denied/permission-denied.dialog';
import { PromptDialog } from './prompt/prompt.dialog';
import { RenameDialog } from './rename/rename.dialog';
import { ResetPasswordDialog } from './reset-password/reset-password.dialog';
import { SuccessDialog } from './success/success.dialog';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatConnectedDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CdkAccordionModule,
  ],
  declarations: [
    ConfirmDialog,
    CopyConfirmDialog,
    DeleteConfirmDialog,
    ErrorDialog,
    InfoDialog,
    LoadingDialog,
    PermissionDeniedDialog,
    PromptDialog,
    RenameDialog,
    SuccessDialog,
    ResetPasswordDialog,
  ],
})
export class BasicDialogModule {}
