import { AfterContentInit, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyModel, PropertyConfigDto } from '@ay-gosu/server-shared';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CompanyService } from '../../../service/company.service';
import { PropertyConfigService } from '../../../service/property-config.service';

@Component({
  selector: 'gosu-company-editor',
  templateUrl: './company-editor.component.html',
  styleUrls: ['./company-editor.component.scss'],
})
export class CompanyEditorComponent implements AfterContentInit {
  public loadPercent = 0;

  public id = new FormControl('');
  public name = new FormControl('');
  public companyForm: FormGroup = new FormGroup({
    id: this.id,
    name: this.name,
  });

  public propertyForm: FormGroup = new FormGroup({});

  public propertyConfigs: PropertyConfigDto[];
  protected oriCompanyFormValue: any;
  protected oriPropertyFormValue: any;
  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _matSnackbar: MatSnackBar,
    private readonly _companyService: CompanyService,
    private readonly _propertyConfigService: PropertyConfigService,
  ) {}

  public ngAfterContentInit(): void {
    try {
      this._loadForm();
    } catch (error) {
      console.error(error);
    }
    this.id.disable();
  }

  private _loadForm() {
    combineLatest([
      this._propertyConfigService.company$,
      this._companyService.company$,
    ])
      .pipe(
        filter(([propertyConfigs, company]) => !!propertyConfigs && !!company),
        takeUntil(this._destroy$),
      )
      .subscribe(([propertyConfigs, company]) => {
        this.propertyConfigs = propertyConfigs;
        Object.keys(this.propertyForm.controls).map((name) =>
          this.propertyForm.removeControl(name),
        );
        propertyConfigs.map((config) => {
          this.propertyForm.addControl(
            config.key,
            new FormControl(company.property[config.key]),
          );
        });
        this.id.setValue(company.id);
        this.name.setValue(company.name);
        this.oriCompanyFormValue = cloneDeep(this.companyForm.value);
        this.oriPropertyFormValue = cloneDeep(this.propertyForm.value);
      });
  }

  public async sendChange() {
    this.loadPercent = 1;

    let modify = 0;

    let { name } = this.companyForm.value;
    if (name !== this.oriCompanyFormValue.name) {
      await CompanyModel.update({ name });
      modify = 1;
    }

    let needUpdated = {};
    let value = this.propertyForm.value;
    for (let key in value) {
      if (this.oriPropertyFormValue[key] != value[key]) {
        needUpdated[key] = {
          value: value[key],
          reason: $localize`從組織設定修改`,
        };
      }
    }

    if (Object.keys(needUpdated).length) {
      await CompanyModel.setProperties(needUpdated);
      modify = 1;
    }

    if (modify) {
      this._companyService.updateCompany$.next(null);
      this._matSnackbar.open($localize`成功修改組織資料`);
    } else {
      this._matSnackbar.open($localize`沒有什麼東西是要修改的...`);
    }

    this.loadPercent = 0;
  }
}
