const isNode = !!(
  typeof process !== 'undefined' &&
  process.versions &&
  process.versions.node
);

export function random() {
  let r = 0;

  if (isNode) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    r = require('crypto').randomBytes(4).readUInt32LE() / 0x100000000;
  } else {
    const crypto =
      window.crypto ||
      window['webkitCrypto'] ||
      window['mozCrypto'] ||
      window['oCrypto'] ||
      window['msCrypto'];
    const randomBuffer: Uint32Array = new Uint32Array(1);

    crypto.getRandomValues(randomBuffer);
    r = randomBuffer[0] / (0xffffffff + 1);
  }

  return r;
}

export function randomInt(max: number): number;
export function randomInt(min: number, max: number): number;
export function randomInt(arg1: number, arg2?: number): number {
  let min: number;
  let max: number;
  if (arg2) {
    min = arg1;
    max = arg2;
  } else {
    min = 0;
    max = arg1;
  }

  const r = Math.floor(random() * (max - min) + min);
  return r;
}
