import { Pipe, PipeTransform } from '@angular/core';
import { PlatformMap, PlatformType } from '../service/platform.service';

@Pipe({ name: 'platform', pure: false })
export class PlatformPipe implements PipeTransform {
  public transform(types: PlatformType | PlatformType[]): any {
    if (!(types instanceof Array)) {
      types = [types];
    }

    return types.map((type) => PlatformMap[type]).join('、');
  }
}
