// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { __Column } from '../../_module/dto/column.dto';

export class TagDto extends __Column {
  public id?: number;
  public name?: string;
  public tagId?: number;
  public companyId?: number;
  public accountId?: number;
  public children?: TagDto[];
  public parent?: TagDto;
  public parentTagId?: number;
  public order?: number;
  public hierarchyLevel?: number;

  /** 擁有子標籤 */
  public hasItems?: boolean;

  /** 適用於使用者 */
  public applyToProfile?: boolean;

  /** 顯示於使用者清單 */
  public showOnProfileList?: boolean;

  /** 顯示於使用者詳細頁面 */
  public showOnProfileDetail?: boolean;

  /** 可被管理員在使用者頁面編輯 */
  public editableOnProfileDetail?: boolean;

  /** 可以被管理員刪除 */
  public accountDeleteable?: boolean;

  /** 可加註於訊息 */
  public applyToPackage?: boolean;

  /** 適用於發送過濾條件 */
  public enableSendFilter?: boolean;

  /** 顯示於訊息清單 */
  public showOnPackageList?: boolean;

  /** 顯示於訊息詳細資料 */
  public showOnPackageDetail?: boolean;

  /** 可以被管理員編輯 */
  public accountEditable?: boolean;

  /** 同一個company下name唯一 */
  public nameUnique?: boolean;

  /** 系統預設標籤，不可被使用者編輯 */
  public isSystemDefault?: boolean;

  /** 可加註於關鍵字 */
  public applyToKeyword?: boolean;

  /** 標籤顏色 */
  public color?: string;
}
// 4316ea2bf42c28dde1faae59a0cc7b036d90c8dbbdcf0112cb917e47a98f46f0
