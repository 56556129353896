import { Directionality } from '@angular/cdk/bidi';
import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayContainer,
  OverlayModule,
  OverlayRef,
  ScrollStrategy,
} from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  TemplatePortal,
} from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ElementRef,
  Inject,
  Injectable,
  InjectionToken,
  Injector,
  NgModule,
  OnDestroy,
  Optional,
  SkipSelf,
  StaticProvider,
  TemplateRef,
  Type,
} from '@angular/core';
import {
  MatDialogConfig,
  MatDialogContainer,
  MatDialogModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MAT_DIALOG_DEFAULT_OPTIONS,
  MAT_DIALOG_SCROLL_STRATEGY,
  _MatDialogBase,
  _MatDialogContainerBase,
} from '@angular/material/dialog';
import { defer, Observable, of, Subject } from 'rxjs';
import { startWith } from 'rxjs/operators';

export {
  MatDialogRef as MatConnectedDialogRef,
  MatDialogContainer as MatConnectedDialogContainer,
};

@NgModule({
  imports: [CommonModule, OverlayModule],
  exports: [MatDialogModule],
})
export class MatConnectedDialogModule {}

export class MatConnectedDialogConfig<D = any> extends MatDialogConfig<D> {
  public elementRef?: ElementRef;
  public overlay?: boolean = false;
  public positions?: ['top' | 'center' | 'bottom', 'start' | 'center' | 'end'] =
    ['center', 'center'];
  public offsetX?: number;
  public offsetY?: number;

  public constructor() {
    super();
    this.maxWidth = null;
    this.offsetX = 8;
    this.offsetY = 8;
  }
}

/**
 * Service to open Material Design modal dialogs.
 */
@Injectable({ providedIn: MatConnectedDialogModule })
export class MatConnectedDialog<
  C extends _MatDialogContainerBase = MatDialogContainer,
> implements OnDestroy
{
  private _openDialogsAtThisLevel: MatDialogRef<any>[] = [];
  private readonly _afterAllClosedAtThisLevel = new Subject<void>();
  private readonly _afterOpenedAtThisLevel = new Subject<MatDialogRef<any>>();
  private _ariaHiddenElements = new Map<Element, string | null>();
  private _scrollStrategy: () => ScrollStrategy;

  /** Keeps track of the currently-open dialogs. */
  public get openDialogs(): MatDialogRef<any>[] {
    return this._parentDialog
      ? this._parentDialog.openDialogs
      : this._openDialogsAtThisLevel;
  }

  /** Stream that emits when a dialog has been opened. */
  public get afterOpened(): Subject<MatDialogRef<any>> {
    return this._parentDialog
      ? this._parentDialog.afterOpened
      : this._afterOpenedAtThisLevel;
  }

  private _getAfterAllClosed(): Subject<void> {
    const parent = this._parentDialog;
    return parent
      ? parent._getAfterAllClosed()
      : this._afterAllClosedAtThisLevel;
  }

  // TODO (jelbourn): tighten the typing right-hand side of this expression.
  /**
   * Stream that emits when all open dialog have finished closing.
   * Will emit on subscribe if there are no open dialogs to begin with.
   */
  public readonly afterAllClosed: Observable<void> = defer(() =>
    this.openDialogs.length
      ? this._getAfterAllClosed()
      : this._getAfterAllClosed().pipe(startWith(undefined)),
  ) as Observable<any>;

  private _dialogRefConstructor: Type<MatDialogRef<any>> = MatDialogRef;
  private _dialogContainerType: Type<C> = MatDialogContainer as any;
  private _dialogDataToken: InjectionToken<any> = MAT_DIALOG_DATA;

  public constructor(
    private _overlay: Overlay,
    private _injector: Injector,
    @Optional()
    @Inject(MAT_DIALOG_DEFAULT_OPTIONS)
    private _defaultOptions: MatDialogConfig,
    @Optional()
    @SkipSelf()
    private _parentDialog: _MatDialogBase<C>,
    private _overlayContainer: OverlayContainer,
    @Inject(MAT_DIALOG_SCROLL_STRATEGY)
    scrollStrategy: any,
  ) {
    this._scrollStrategy = scrollStrategy;
  }

  /**
   * Opens a modal dialog containing the given component.
   * @param componentOrTemplateRef Type of the component to load into the dialog,
   *     or a TemplateRef to instantiate as the dialog content.
   * @param config Extra configuration options.
   * @returns Reference to the newly-opened dialog.
   */
  public open<T, D = any, R = any>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    config?: MatConnectedDialogConfig<D>,
  ): MatDialogRef<T, R> {
    config = _applyConfigDefaults(
      config,
      this._defaultOptions || new MatConnectedDialogConfig(),
    );

    if (config.id && this.getDialogById(config.id)) {
      throw Error(
        `Dialog with id "${config.id}" exists already. The dialog id must be unique.`,
      );
    }

    const overlayRef = this._createOverlay(config);
    const dialogContainer = this._attachDialogContainer(overlayRef, config);
    const dialogRef = this._attachDialogContent<T, R>(
      componentOrTemplateRef,
      dialogContainer,
      overlayRef,
      config,
    );

    // If this is the first dialog that we're opening, hide all the non-overlay content.
    if (!this.openDialogs.length) {
      this._hideNonDialogContentFromAssistiveTechnology();
    }

    this.openDialogs.push(dialogRef);
    dialogRef.afterClosed().subscribe(() => this._removeOpenDialog(dialogRef));
    this.afterOpened.next(dialogRef);

    // Notify the dialog container that the content has been attached.
    dialogContainer._initializeWithAttachedContent();

    return dialogRef;
  }

  /**
   * Closes all of the currently-open dialogs.
   */
  public closeAll(): void {
    this._closeDialogs(this.openDialogs);
  }

  /**
   * Finds an open dialog by its id.
   * @param id ID to use when looking up the dialog.
   */
  public getDialogById(id: string): MatDialogRef<any> | undefined {
    return this.openDialogs.find((dialog) => dialog.id === id);
  }

  public ngOnDestroy() {
    // Only close the dialogs at this level on destroy
    // since the parent service may still be active.
    this._closeDialogs(this._openDialogsAtThisLevel);
    this._afterAllClosedAtThisLevel.complete();
    this._afterOpenedAtThisLevel.complete();
  }

  /**
   * Creates the overlay into which the dialog will be loaded.
   * @param config The dialog configuration.
   * @returns A promise resolving to the OverlayRef for the created overlay.
   */
  private _createOverlay(config: MatConnectedDialogConfig): OverlayRef {
    const overlayConfig = this._getOverlayConfig(config);
    return this._overlay.create(overlayConfig);
  }

  /**
   * Creates an overlay config from a dialog config.
   * @param dialogConfig The dialog configuration.
   * @returns The overlay configuration.
   */
  private _getOverlayConfig(
    dialogConfig: MatConnectedDialogConfig,
  ): OverlayConfig {
    const positionStrategy = dialogConfig.elementRef
      ? this._getConnectedPositionStrategy(dialogConfig)
      : this._overlay.position().global();

    const state = new OverlayConfig({
      positionStrategy,
      scrollStrategy: dialogConfig.scrollStrategy || this._scrollStrategy(),
      panelClass: dialogConfig.panelClass,
      hasBackdrop: dialogConfig.hasBackdrop,
      direction: dialogConfig.direction,
      minWidth: dialogConfig.minWidth,
      minHeight: dialogConfig.minHeight,
      maxWidth: dialogConfig.maxWidth,
      maxHeight: dialogConfig.maxHeight,
      disposeOnNavigation: dialogConfig.closeOnNavigation,
    });

    if (dialogConfig.backdropClass) {
      state.backdropClass = dialogConfig.backdropClass;
    }

    return state;
  }
  private _getConnectedPositionStrategy(
    dialogConfig: MatConnectedDialogConfig,
  ) {
    const positions = this._getPosition(dialogConfig);

    return this._overlay
      .position()
      .flexibleConnectedTo(dialogConfig.elementRef)
      .withLockedPosition()
      .withPositions(positions);
  }

  private _getPosition(config: MatConnectedDialogConfig): ConnectedPosition[] {
    if (config.overlay) {
      const [y, x] = config.positions;
      return [{ originX: x, overlayX: x, originY: y, overlayY: y }];
    } else {
      return [
        {
          offsetX: 0,
          offsetY: -config.offsetY,
          originX: 'center',
          overlayX: 'center',
          originY: 'top',
          overlayY: 'bottom',
        },
        {
          offsetX: 0,
          offsetY: config.offsetY,
          originX: 'center',
          overlayX: 'center',
          originY: 'bottom',
          overlayY: 'top',
        },
        {
          offsetX: config.offsetX,
          offsetY: 0,
          originX: 'end',
          overlayX: 'start',
          originY: 'center',
          overlayY: 'center',
        },
        {
          offsetX: -config.offsetX,
          offsetY: 0,
          originX: 'start',
          overlayX: 'end',
          originY: 'center',
          overlayY: 'center',
        },
        {
          offsetX: 0,
          offsetY: config.offsetY,
          originX: 'start',
          overlayX: 'start',
          originY: 'bottom',
          overlayY: 'top',
        },
        {
          offsetX: 0,
          offsetY: config.offsetY,
          originX: 'end',
          overlayX: 'end',
          originY: 'bottom',
          overlayY: 'top',
        },
        {
          offsetX: 0,
          offsetY: -config.offsetY,
          originX: 'start',
          overlayX: 'start',
          originY: 'top',
          overlayY: 'bottom',
        },
        {
          offsetX: 0,
          offsetY: -config.offsetY,
          originX: 'end',
          overlayX: 'end',
          originY: 'top',
          overlayY: 'bottom',
        },
      ];
    }
  }

  /**
   * Attaches an MatDialogContainer to a dialog's already-created overlay.
   * @param overlay Reference to the dialog's underlying overlay.
   * @param config The dialog configuration.
   * @returns A promise resolving to a ComponentRef for the attached container.
   */
  private _attachDialogContainer(
    overlay: OverlayRef,
    config: MatConnectedDialogConfig,
  ): C {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;
    const injector = Injector.create({
      parent: userInjector || this._injector,
      providers: [{ provide: MatDialogConfig, useValue: config }],
    });

    const containerPortal = new ComponentPortal(
      this._dialogContainerType,
      config.viewContainerRef,
      injector,
      config.componentFactoryResolver,
    );

    const containerRef = overlay.attach<C>(containerPortal);

    return containerRef.instance;
  }

  /**
   * Attaches the user-provided component to the already-created MatDialogContainer.
   * @param componentOrTemplateRef The type of component being loaded into the dialog,
   *     or a TemplateRef to instantiate as the content.
   * @param dialogContainer Reference to the wrapping MatDialogContainer.
   * @param overlayRef Reference to the overlay in which the dialog resides.
   * @param config The dialog configuration.
   * @returns A promise resolving to the MatDialogRef that should be returned to the user.
   */
  private _attachDialogContent<T, R>(
    componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
    dialogContainer: C,
    overlayRef: OverlayRef,
    config: MatConnectedDialogConfig,
  ): MatDialogRef<T, R> {
    // Create a reference to the dialog we're creating in order to give the user a handle
    // to modify and close it.
    const dialogRef = new this._dialogRefConstructor(
      overlayRef,
      dialogContainer,
      config.id,
    );

    if (componentOrTemplateRef instanceof TemplateRef) {
      dialogContainer.attachTemplatePortal(
        new TemplatePortal<T>(componentOrTemplateRef, null!, <any>{
          $implicit: config.data,
          dialogRef,
        }),
      );
    } else {
      const injector = this._createInjector<T>(
        config,
        dialogRef,
        dialogContainer,
      );
      const contentRef = dialogContainer.attachComponentPortal<T>(
        new ComponentPortal(
          componentOrTemplateRef,
          config.viewContainerRef,
          injector,
        ),
      );
      dialogRef.componentInstance = contentRef.instance;
    }

    if (!config.elementRef) {
      dialogRef
        .updateSize(config.width, config.height)
        .updatePosition(config.position);
    }

    return dialogRef;
  }

  /**
   * Creates a custom injector to be used inside the dialog. This allows a component loaded inside
   * of a dialog to close itself and, optionally, to return a value.
   * @param config Config object that is used to construct the dialog.
   * @param dialogRef Reference to the dialog.
   * @param container Dialog container element that wraps all of the contents.
   * @returns The custom injector that can be used inside the dialog.
   */
  private _createInjector<T>(
    config: MatConnectedDialogConfig,
    dialogRef: MatDialogRef<T>,
    dialogContainer: C,
  ): Injector {
    const userInjector =
      config && config.viewContainerRef && config.viewContainerRef.injector;

    // The dialog container should be provided as the dialog container and the dialog's
    // content are created out of the same `ViewContainerRef` and as such, are siblings
    // for injector purposes. To allow the hierarchy that is expected, the dialog
    // container is explicitly provided in the injector.
    const providers: StaticProvider[] = [
      { provide: this._dialogContainerType, useValue: dialogContainer },
      { provide: this._dialogDataToken, useValue: config.data },
      { provide: this._dialogRefConstructor, useValue: dialogRef },
    ];

    if (
      config.direction &&
      (!userInjector ||
        !userInjector.get<Directionality | null>(Directionality, null))
    ) {
      providers.push({
        provide: Directionality,
        useValue: { value: config.direction, change: of() },
      });
    }

    return Injector.create({
      parent: userInjector || this._injector,
      providers,
    });
  }

  /**
   * Removes a dialog from the array of open dialogs.
   * @param dialogRef Dialog to be removed.
   */
  private _removeOpenDialog(dialogRef: MatDialogRef<any>) {
    const index = this.openDialogs.indexOf(dialogRef);

    if (index > -1) {
      this.openDialogs.splice(index, 1);

      // If all the dialogs were closed, remove/restore the `aria-hidden`
      // to a the siblings and emit to the `afterAllClosed` stream.
      if (!this.openDialogs.length) {
        this._ariaHiddenElements.forEach((previousValue, element) => {
          if (previousValue) {
            element.setAttribute('aria-hidden', previousValue);
          } else {
            element.removeAttribute('aria-hidden');
          }
        });

        this._ariaHiddenElements.clear();
        this._getAfterAllClosed().next();
      }
    }
  }

  /**
   * Hides all of the content that isn't an overlay from assistive technology.
   */
  private _hideNonDialogContentFromAssistiveTechnology() {
    const overlayContainer = this._overlayContainer.getContainerElement();

    // Ensure that the overlay container is attached to the DOM.
    if (overlayContainer.parentElement) {
      const siblings = overlayContainer.parentElement.children;

      for (let i = siblings.length - 1; i > -1; i--) {
        let sibling = siblings[i];

        if (
          sibling !== overlayContainer &&
          sibling.nodeName !== 'SCRIPT' &&
          sibling.nodeName !== 'STYLE' &&
          !sibling.hasAttribute('aria-live')
        ) {
          this._ariaHiddenElements.set(
            sibling,
            sibling.getAttribute('aria-hidden'),
          );
          sibling.setAttribute('aria-hidden', 'true');
        }
      }
    }
  }

  /** Closes all of the dialogs in an array. */
  private _closeDialogs(dialogs: MatDialogRef<any>[]) {
    let i = dialogs.length;

    while (i--) {
      // The `_openDialogs` property isn't updated after close until the rxjs subscription
      // runs on the next microtask, in addition to modifying the array as we're going
      // through it. We loop through all of them and call close without assuming that
      // they'll be removed from the list instantaneously.
      dialogs[i].close();
    }
  }
}

/**
 * Applies default options to the dialog config.
 * @param config Config to be modified.
 * @param defaultOptions Default options provided.
 * @returns The new configuration object.
 */
function _applyConfigDefaults(
  config?: MatConnectedDialogConfig,
  defaultOptions?: MatConnectedDialogConfig,
): MatConnectedDialogConfig {
  return { ...defaultOptions, ...config };
}
