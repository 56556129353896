// 透過 @ay-nestjs/share 產生
/* eslint-disable */

export class __Column {
  public id?: number;
  public createdAt?: Date;
  public updatedAt?: Date;
  public deletedAt?: Date;
  public toJSON?: () => any;
  public get?: (() => any) | any;
}
// a86755acb6dd2aa4e5927f905b91da17181a40a8f1b09591390493958ad829a1
