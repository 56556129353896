// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { PropertyConfigDto } from './dto/property-config.dto';

export class PropertyConfigModel {
  static getAll(): Promise<PropertyConfigDto[]> {
    return wsc.execute('/ws/property-config/getAll') as any;
  }

  static get(id: number): Promise<PropertyConfigDto> {
    return wsc.execute('/ws/property-config/get', id) as any;
  }

  static update(id: number, config: PropertyConfigDto): Promise<boolean> {
    return wsc.execute('/ws/property-config/update', id, config) as any;
  }

  static create(config: PropertyConfigDto): Promise<number> {
    return wsc.execute('/ws/property-config/create', config) as any;
  }

  static remove(id: number): Promise<boolean> {
    return wsc.execute('/ws/property-config/remove', id) as any;
  }
}
// 4d573239206ee70f1a70cab370f8ff25a2d490cb80a9df82cb9ffe77f8bdf31a
