import { Component, Input, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dl-prompt-dialog',
  templateUrl: './prompt.dialog.html',
  styleUrls: ['./prompt.dialog.scss'],
})
export class PromptDialog implements OnDestroy {
  @Input()
  public confirm: string = $localize`確認`;

  @Input()
  public title: string = $localize`請輸入`;

  @Input()
  public placeholder: string = '';

  @Input()
  public required: string = $localize`必填項目`;

  //#region value
  private _value: string = '';

  public value$ = new Subject<string>();

  public get value(): string {
    return this._value;
  }

  public set value(value: string) {
    if (this._value === value) return;
    this._value = value;
    this.value$.next(this._value);
  }
  //#endregion value

  public valueInputControl = new FormControl(this.value, Validators.required);

  public form = new FormGroup({ value: this.valueInputControl });

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private readonly _matDialogRef: MatDialogRef<PromptDialog>,
  ) {}

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _updateInputControlWhenOriNameChanged = this.value$
    .pipe(takeUntil(this._destroy$))
    .subscribe((value) => this.valueInputControl.setValue(value));

  public onSubmit() {
    this._matDialogRef.close(this.valueInputControl.value);
  }
}
