// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { DirectusCollectionDto } from './dto/collection.dto';
import { DirectusFieldDto } from './dto/field.dto';

export class DirectusModel {
  static fetchCollection(): Promise<DirectusCollectionDto[]> {
    return wsc.execute('/ws/directus/fetchCollection') as any;
  }

  static fetchField(name: string): Promise<DirectusFieldDto> {
    return wsc.execute('/ws/directus/fetchField', name) as any;
  }

  static fetchLoginUrl(): Promise<string> {
    return wsc.execute('/ws/directus/fetchLoginUrl') as any;
  }
}
// 358e1ca2a33e8c7b924cdcef993421b5467b5ece43a2c8c66e16dae6e7770e99
