import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { InlineSVGModule } from '../../ng-inline-svg';
import { IconComponent } from './icon.component';

@NgModule({
  imports: [CommonModule, MatIconModule, FontAwesomeModule, InlineSVGModule],
  declarations: [IconComponent],
  exports: [IconComponent],
})
export class IconModule {}
