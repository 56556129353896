import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quickReplyType',
  pure: true,
})
export class QuickReplyTypePipe implements PipeTransform {
  public transform(input: string): string {
    switch (input) {
      case 'next':
        return $localize`執行下一個流程`;
      case 'email':
        return $localize`電子郵件`;
      case 'phone':
        return $localize`手機號碼`;
      case 'date':
        return $localize`選擇日期`;
      case 'camera':
        return $localize`開啟相機`;
      case 'album':
        return $localize`開啟相簿`;
      case 'postback':
        return $localize`回傳資訊`;
      case 'message':
        return $localize`幫使用者下指令`;
      case 'location':
        return $localize`地理位置`;
      default:
        return input;
    }
  }
}
