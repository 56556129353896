import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeHant from '@angular/common/locales/zh-Hant';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import {
  MatSnackBarModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
} from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import * as Sentry from '@sentry/angular';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { ElementRefModule } from './components/element-ref.directive';
import { GosuIconModule } from './components/gosu-icon';
import { GosuLogoModule } from './components/gosu-logo';
import { IconModule } from './components/icon';
import { MonacoEditorModule } from './components/ngx-monaco-editor/editor.module';
import { PageLoadingModule } from './components/page-loading';
import { ToolbarModule } from './components/toolbar/toolbar.module';
import { monacoConfig } from './definition/monaco';
import { BasicDialogModule } from './dialog/basic';
import { GosuErrorHandler } from './error-handler';
import { MatConnectedDialogModule } from './material/connected-dialog';
import { MatTooltipModule } from './material/tooltip';
import { ActivityRecordComponent } from './pages/activity-record/activity-record.component';
import { ReservationDatetimePickerComponent } from './pages/broadcast/reservation-datetime-picker/reservation-datetime-picker.component';
import { CompanyEditorComponent } from './pages/company/company-editor/company-editor.component';
import { DirectusComponent } from './pages/directus/directus.component';
import { LandingPageModule } from './pages/landing/landing.module';
import { SelectCompanyComponent } from './pages/login/select-company/select-company.component';
import { PipeModule } from './pipe/pipe.module';
import { PartnerProvider } from './provider';
import { ConnectionService } from './service/connection.service';
import { GTM_ID } from './service/google-tag-manager.service';
import { I18nService } from './service/i18n.service';
import { TokenService } from './service/token.service';

registerLocaleData(localeHant, 'zh-Hant');

@NgModule({
  declarations: [
    ActivityRecordComponent,
    AppComponent,
    DirectusComponent,
    CompanyEditorComponent,
    SelectCompanyComponent,
    ReservationDatetimePickerComponent,
  ],
  imports: [
    CommonModule,
    MatConnectedDialogModule,
    AppRoutingModule,
    BasicDialogModule,
    BrowserAnimationsModule,
    BrowserModule,
    ElementRefModule,
    FlexLayoutModule,
    FontAwesomeModule,
    FormsModule,
    GosuIconModule,
    GosuLogoModule,
    IconModule,
    LandingPageModule,
    PageLoadingModule,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatTooltipModule,
    MatExpansionModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    PipeModule,
    HttpClientModule,
    RouterModule,
    ToolbarModule,
    MonacoEditorModule.forRoot(monacoConfig),
  ],
  providers: [
    TokenService,
    ConnectionService,
    I18nService,
    { provide: LOCALE_ID, useValue: 'zh-Hant' },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: GTM_ID, useValue: environment.gtmId },
    {
      provide: ErrorHandler,
      useClass: GosuErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
      },
    },
    PartnerProvider.landing,
    PartnerProvider.copyright,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
