<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" [ngClass]="theme">
  <rect
    class="node"
    x="6"
    y="8.5"
    width="20"
    height="7.5"
    rx="3.75"
    ry="3.75"
  />
  <polyline
    [attr.stroke-width]="(32 / size) * 2 + 'px'"
    class="line"
    points="26 12.25 31 12.25 31 1 1 1 1 23.5 18.5 23.5 26 31 26 23.5 31 23.5 31 16"
  />
  <circle class="junction" cx="26" cy="12.25" r="1.25" />
</svg>
