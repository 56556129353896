import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export function getExpansionTrigger() {
  return trigger('expansion', [
    state('false', style({ height: '0px' })),
    state('true', style({ height: '*' })),
    transition('* <=> *', animate(`.2s ease-in`)),
  ]);
}
