import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { InlineSVGDirective } from './inline-svg.directive';
import { InlineSVGService } from './inline-svg.service';

@Component({
  selector: 'inline-svg',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InlineSVGComponent implements AfterViewInit, OnChanges {
  @Input()
  public context: InlineSVGDirective;

  @Input()
  public content: HTMLElement | SVGElement;

  @Input()
  public replaceContents: boolean;

  @Input()
  public prepend: boolean;

  public constructor(
    private readonly _inlineSVGService: InlineSVGService,
    public readonly _elementRef: ElementRef,
  ) {
    this._elementRef = _elementRef;
  }

  public ngAfterViewInit(): void {
    this._updateContent();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['content']) {
      this._updateContent();
    }
  }

  private _updateContent(): void {
    this._inlineSVGService.insertEl(
      this.context,
      this._elementRef.nativeElement,
      this.content,
      this.replaceContents,
      this.prepend,
    );
  }
}
