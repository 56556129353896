import { Pipe, PipeTransform } from '@angular/core';
import unescape from 'lodash/unescape';

@Pipe({
  name: 'unescape',
})
export class UnescapePipe implements PipeTransform {
  public transform(value: any, args?: any): any {
    return unescape(value);
  }
}
