import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dl-success-dialog',
  templateUrl: './success.dialog.html',
  styleUrls: ['./success.dialog.scss'],
})
export class SuccessDialog {
  @Input()
  public title: string = $localize`成功`;

  @Input()
  public confirm: string = 'OK';

  @Input()
  public content: string = '';

  public constructor(
    private readonly _matDialogRef: MatDialogRef<SuccessDialog>,
  ) {}

  public close() {
    this._matDialogRef.close();
  }
}
