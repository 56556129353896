import { Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dl-rename-dialog',
  templateUrl: './rename.dialog.html',
  styleUrls: ['./rename.dialog.scss'],
})
export class RenameDialog implements OnDestroy {
  //#region name
  private _name: string = '';

  public name$ = new Subject<string>();

  public get name(): string {
    return this._name;
  }

  public set name(name: string) {
    if (this._name === name) return;
    this._name = name;
    this.name$.next(this._name);
  }
  //#endregion name

  public nameInputControl = new FormControl(this.name, Validators.required);

  public form = new FormGroup({ name: this.nameInputControl });

  private readonly _destroy$ = new Subject<void>();

  public constructor(
    private _matDialogRef: MatDialogRef<RenameDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { name: string },
  ) {
    this.name = data.name;
  }

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private _updateInputControlWhenOriNameChanged = this.name$
    .pipe(takeUntil(this._destroy$))
    .subscribe((name) => this.nameInputControl.setValue(name));

  public onSubmit() {
    this._matDialogRef.close(this.nameInputControl.value);
  }
}
