import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { BasicDialog } from './dialog/basic';

@Injectable()
export class SaveReminder implements CanDeactivate<any> {
  public isActive = false;
  private _isShowConfirm = false;

  public constructor(private readonly _basicDialog: BasicDialog) {}

  public async leaveConfirm(title?: string): Promise<boolean> {
    this._isShowConfirm = true;
    let res = await this._basicDialog.confirm(
      title || $localize`操作尚未儲存，確定要離開嗎？`,
    );
    this._isShowConfirm = false;
    return res;
  }

  public async canDeactivate(
    component: any,
    route: ActivatedRouteSnapshot,
    now: RouterStateSnapshot,
  ): Promise<boolean> {
    if (this._isShowConfirm) return false;
    if (!this.isActive) return true;
    let res = await this.leaveConfirm();
    this.isActive = !res;
    return res;
  }
}
