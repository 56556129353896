import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

export function getVerticalSlideTrigger(
  name: string = 'slide',
  second: number = 0.2,
  from: number = -100,
  to: number = 0,
) {
  return trigger(name, [
    state('false', style({ transform: `translateY(${from}%)` })),
    state('true', style({ transform: `translateY(${to}%)` })),
    transition('* <=> *', animate(`${second}s ease-in`)),
  ]);
}

export function getSlideDownTrigger(
  name: string = 'slideDown',
  second: number = 0.2,
  from: number = -100,
  to: number = 0,
) {
  return getVerticalSlideTrigger(name, second, from, to);
}

export function getSlideUpTrigger(
  name: string = 'slideUp',
  second: number = 0.2,
  from: number = 100,
  to: number = 0,
) {
  return getVerticalSlideTrigger(name, second, from, to);
}
