import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  public init() {
    if ($localize.locale === 'en') {
      moment.locale('en');
    } else {
      moment.locale('zh_tw');
    }
  }
}
