import { Injectable } from '@angular/core';
import { BotDto, BotModel } from '@ay-gosu/server-shared';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ReadableError } from '../../util/readable-error';
import { PreloadService } from './preload.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class BotService extends PreloadService<BotDto> {
  public enabled$ = this.all$.pipe(
    map((bots) => bots.filter((bot) => !bot.disabled)),
  );

  public constructor(protected _tokenService: TokenService) {
    super(_tokenService);
  }

  public async load(): Promise<BotDto[]> {
    return BotModel.list();
  }

  public get(id: number): Promise<BotDto> {
    return BotModel.getById(id);
  }

  public getAllByIds(ids: number[]): Observable<BotDto[]> {
    return this.all$.pipe(
      map((bots) => (bots ? bots.filter((bot) => ids.includes(bot.id)) : [])),
    );
  }

  public getById(id: number): Observable<BotDto> {
    return this.all$.pipe(map((bots) => bots.find((bot) => bot.id === id)));
  }

  public async update(bot: Partial<BotDto>) {
    if (!bot.id) {
      throw new ReadableError(
        $localize`更新機器人資料時發生錯誤，沒有指定的機器人編號`,
        { bot },
      );
    }

    let res = await BotModel.update(bot.id, bot);
    this.afterUpdate(bot.id);
    return res;
  }

  public async createLineBot(
    channelId: string,
    channelSecret: string,
    channelAccessToken: string,
  ): Promise<BotDto> {
    if (!channelId || !channelSecret || !channelAccessToken) {
      return;
    }

    const bot = await BotModel.createLineMessagingApiBot(
      channelId.trim(),
      channelSecret.trim(),
      channelAccessToken.trim(),
      environment.webhookUrl.replace('[platform]', 'line'),
    );

    await this.afterUpdate(bot.id);
    return bot;
  }

  public afterUpdate(botId: number) {
    return super.afterUpdate(botId);
  }
}
