// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { LingtelliGroupFrameDto } from './dto/group-frame.dto';
import { LingtelliGroupDto } from './dto/group.dto';

export class LingtelliModel {
  static getChatbotList(): Promise<{ id: number; chatbotName: string }[]> {
    return wsc.execute('/ws/lingtelli/getChatbotList') as any;
  }

  static verifyAccessToken(lingtelliId: number): Promise<boolean> {
    return wsc.execute('/ws/lingtelli/verifyAccessToken', lingtelliId) as any;
  }

  static createGroup(lingtelliId: number, question: string): Promise<boolean> {
    return wsc.execute(
      '/ws/lingtelli/createGroup',
      lingtelliId,
      question,
    ) as any;
  }

  static createQuestion(
    lingtelliId: number,
    group: number,
    question: string,
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/lingtelli/createQuestion',
      lingtelliId,
      group,
      question,
    ) as any;
  }

  static updateQuestion(
    lingtelliId: number,
    questionId: number,
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/lingtelli/updateQuestion',
      lingtelliId,
      questionId,
    ) as any;
  }

  static deleteQuestion(
    lingtelliId: number,
    questionId: number,
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/lingtelli/deleteQuestion',
      lingtelliId,
      questionId,
    ) as any;
  }

  static deleteGroup(lingtelliId: number, group: number): Promise<boolean> {
    return wsc.execute('/ws/lingtelli/deleteGroup', lingtelliId, group) as any;
  }

  static trainChatbot(lingtelliId: number): Promise<boolean> {
    return wsc.execute('/ws/lingtelli/trainChatbot', lingtelliId) as any;
  }

  static createChatbot(username: string, password: string): Promise<boolean> {
    return wsc.execute(
      '/ws/lingtelli/createChatbot',
      username,
      password,
    ) as any;
  }

  static getGroups(
    lingtelliId: number,
    content = '',
    page = 1,
  ): Promise<LingtelliGroupFrameDto> {
    return wsc.execute(
      '/ws/lingtelli/getGroups',
      lingtelliId,
      content,
      page,
    ) as any;
  }

  static getGroup(
    lingtelliId: number,
    group: number,
  ): Promise<LingtelliGroupDto> {
    return wsc.execute('/ws/lingtelli/getGroup', lingtelliId, group) as any;
  }
}
// 77eecaf2ca43c663e29dcbc5e2168382f5c562bf5acf29c8120fe40f191f49b7
