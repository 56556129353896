// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { CreateTicketEventResponseDto } from './dto/create-ticket-event-response.dto';
import { CreateTicketEventDto } from './dto/create-ticket-event.dto';
import { FetchTicketEventResponseDto } from './dto/fetch-ticket-event-response.dto';
import { ListTicketEventsResponseDto } from './dto/list-ticket-events-response.dto';
import { ListTicketsResponseDto } from './dto/list-tickets-response.dto';
import { UpdateTicketEventBodyDto } from './dto/update-ticket-event.dto';

export class TicketEventModel {
  static listTicketEvent(
    page = 1,
    pageSize = 50,
  ): Promise<ListTicketEventsResponseDto> {
    return wsc.execute(
      '/ws/ticket-event/listTicketEvent',
      page,
      pageSize,
    ) as any;
  }

  static fetch(ticketEventId: number): Promise<FetchTicketEventResponseDto> {
    return wsc.execute('/ws/ticket-event/fetch', ticketEventId) as any;
  }

  static createTicketEvent(
    body: CreateTicketEventDto,
  ): Promise<CreateTicketEventResponseDto> {
    return wsc.execute('/ws/ticket-event/createTicketEvent', body) as any;
  }

  static updateTicketEvent(
    ticketEventId: number,
    data: UpdateTicketEventBodyDto,
  ): Promise<void> {
    return wsc.execute(
      '/ws/ticket-event/updateTicketEvent',
      ticketEventId,
      data,
    ) as any;
  }

  static deleteTicketEvent(ticketEventId: number): Promise<void> {
    return wsc.execute(
      '/ws/ticket-event/deleteTicketEvent',
      ticketEventId,
    ) as any;
  }

  static listTickets(ticketEventId: number): Promise<ListTicketsResponseDto> {
    return wsc.execute('/ws/ticket-event/listTickets', ticketEventId) as any;
  }
}
// a0dc3d3a42ba5438b41c99d033eddf8ecb552b2333dccba280d4663b060ec0db
