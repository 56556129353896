import { Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
export const GTM_ID = 'GTM_ID';

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {
  public constructor(
    @Inject(GTM_ID)
    private readonly _gtmId: string,
    private readonly _router: Router,
  ) {}

  public init() {
    if (!this._gtmId) {
      return;
    }

    this._injectScript();
    this._gtmStart();
    this._subscribeRouter();
  }

  private _gtmStart() {
    this._addDataLayer({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    });
  }

  private _injectScript() {
    /** inject script to head */
    const gtmScript = document.createElement('script');
    gtmScript.id = 'GTM-script';
    gtmScript.async = true;
    gtmScript.src = '//www.googletagmanager.com/gtm.js?id=' + this._gtmId;
    document.head.insertBefore(gtmScript, document.head.firstChild);

    /** create iframe */
    const iframe = document.createElement('iframe');
    iframe.id = 'GTM-iframe';
    iframe.setAttribute(
      'src',
      '//www.googletagmanager.com/ns.html?id=' + this._gtmId,
    );
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    /** inject script to head */
    const noscript = document.createElement('noscript');
    noscript.id = 'GTM-noscript';
    noscript.appendChild(iframe);

    document.body.insertBefore(noscript, document.body.firstChild);
  }

  private _subscribeRouter() {
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged<NavigationEnd>(
          (previous, current) => previous.url === current.url,
        ),
      )
      .subscribe((event: NavigationEnd) => {
        this._addDataLayer({
          event: 'page',
          pageName: event.urlAfterRedirects,
        });
      });
  }

  private _addDataLayer(data: any) {
    if (!data) return;
    this._dataLayer.push(data);
  }

  private get _dataLayer() {
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }
}
