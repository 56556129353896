// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Content, PrivateMessage, QuickReply } from '@ay/bot';
import _ from 'lodash';
import { Observable } from 'rxjs';
import { PackageFetchForBroadcast } from './dto/fetch-for-broadcast.type';
import { PackageFetchForList } from './dto/fetch-for-list.type';
import { PackageFetchForPromotionChannel } from './dto/fetch-for-promotion-channel.type';
import { PackageFetchForSendNode } from './dto/fetch-for-send-node.type';
import { PackageSaveForBroadcast } from './dto/save-for-broadcast.type';
import { PackageSaveForSendNode } from './dto/save-for-send-node.type';
import { PackageSaveForTemplate } from './dto/save-for-template.type';
import { PackageSendImmediately } from './dto/send-immediately.type';
import { PackageSendProcess } from './dto/send-process.type';
import { PackageSendReservation } from './dto/send-reservation.type';
import { PackageStatistic } from './dto/statistic.type';

export class PackageModel {
  static fetchForList(
    packageIds: number[],
  ): Promise<_.Dictionary<PackageFetchForList>> {
    return wsc.execute('/ws/package/fetchForList', packageIds) as any;
  }

  static fetchForBroadcast(
    packageId: number,
  ): Promise<PackageFetchForBroadcast> {
    return wsc.execute('/ws/package/fetchForBroadcast', packageId) as any;
  }

  static fetchStatistic(packageId: number): Promise<PackageStatistic> {
    return wsc.execute('/ws/package/fetchStatistic', packageId) as any;
  }

  static fetchForSendNode(
    packageIds: number[],
  ): Promise<_.Dictionary<PackageFetchForSendNode>> {
    return wsc.execute('/ws/package/fetchForSendNode', packageIds) as any;
  }

  static fetchForPromotionChannel(
    packageId: number,
  ): Promise<PackageFetchForPromotionChannel> {
    return wsc.execute(
      '/ws/package/fetchForPromotionChannel',
      packageId,
    ) as any;
  }

  static saveForBroadcast(data: PackageSaveForBroadcast): Promise<number> {
    return wsc.execute('/ws/package/saveForBroadcast', data) as any;
  }

  static saveForTemplate(data: PackageSaveForTemplate): Promise<number> {
    return wsc.execute('/ws/package/saveForTemplate', data) as any;
  }

  static saveForSendNode(data: PackageSaveForSendNode): Promise<number> {
    return wsc.execute('/ws/package/saveForSendNode', data) as any;
  }

  static sendImmediately(
    data: PackageSendImmediately,
  ): Observable<PackageSendProcess> {
    return wsc.subscribe('/ws/package/sendImmediately', data) as any;
  }

  static sendReservation(data: PackageSendReservation): Promise<void> {
    return wsc.execute('/ws/package/sendReservation', data) as any;
  }

  static sendPrivateMessage(
    profileId: number,
    records: Content.Any[],
    quickReplies: QuickReply.Any[],
  ): Promise<void> {
    return wsc.execute(
      '/ws/package/sendPrivateMessage',
      profileId,
      records,
      quickReplies,
    ) as any;
  }

  static cancelReservation(packageId: number): Promise<void> {
    return wsc.execute('/ws/package/cancelReservation', packageId) as any;
  }

  static delete(packageId: number): Promise<void> {
    return wsc.execute('/ws/package/delete', packageId) as any;
  }

  static updateTagIds(packageId: number, tagIds: number[]): Promise<void> {
    return wsc.execute('/ws/package/updateTagIds', packageId, tagIds) as any;
  }

  static privateMessage(): Observable<PrivateMessage> {
    return wsc.subscribe('/ws/package/privateMessage') as any;
  }
}
// e5098b7b15d90700a01b73a9aeb1b3e7b7fd1e4b51dbc80fb602abc9a269a7f3
