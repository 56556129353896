import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'imageType' })
export class ImageTypePipe implements PipeTransform {
  public transform(image: string): string {
    if (typeof image != 'string') return;
    let code =
      'https://gosubarpublic.blob.core.windows.net/static/20191210172055_558_1018_bb608739-80e9-40c5-a7e8-c0889680d24b.png';

    let pattern = new RegExp(/\$\{.*\}/g);
    if (pattern.test(image)) image = code;
    return image;
  }
}
