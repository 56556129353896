import { Component, Input } from '@angular/core';

@Component({
  selector: 'gosu-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
})
export class LogoComponent {
  @Input()
  public theme: 'light' | 'dark' | 'primary' | 'yellow' = 'light';

  @Input()
  public size = 240;
}
