<div class="wrap" [style.width.px]="size" [style.height.px]="(size / 240) * 34">
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 240 32.3"
    [ngClass]="theme"
  >
    <path
      class="text"
      d="M118.4,32.3c-6.9,0-12.4-5.6-12.4-12.4V1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3v18.5c0,5.4,4.4,9.8,9.8,9.8  s9.8-4.4,9.8-9.8V1.3c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v18.5C130.8,26.7,125.2,32.3,118.4,32.3z"
    />
    <path
      class="text"
      d="M88.7,16.1c-0.3,0-0.7-0.1-0.9-0.4l-3.9-3.9c-2.7-2.7-2.7-7,0-9.7s7.1-2.7,9.8,0L97.5,6c0.5,0.5,0.5,1.4,0,1.9  s-1.4,0.5-1.9,0L91.7,4c-0.8-0.8-1.9-1.2-3-1.2l0,0c-1.1,0-2.2,0.5-3,1.3c-1.6,1.6-1.6,4.3,0,6l3.9,3.9c0.5,0.5,0.5,1.4,0,1.9  C89.4,16,89.1,16.1,88.7,16.1z"
    />
    <path
      class="text"
      d="M88.7,32.3c-2.1,0-4.1-0.8-5.7-2.3l-4.7-4.7c-0.5-0.5-0.5-1.4,0-1.9s1.4-0.5,1.9,0l4.7,4.7c2.1,2.1,5.5,2.1,7.6,0  s2.1-5.5,0-7.6l-4.7-4.7c-0.5-0.5-0.5-1.4,0-1.9s1.4-0.5,1.9,0l4.7,4.7c3.1,3.1,3.1,8.2,0,11.3C92.8,31.5,90.8,32.3,88.7,32.3z"
    />
    <path
      class="text"
      d="M107.3,18.1c-0.1,0-0.2,0-0.3-0.1L96.3,7.2c-0.2-0.2-0.2-0.5,0-0.6c0.2-0.2,0.5-0.2,0.6,0l10.7,10.7c0.2,0.2,0.2,0.5,0,0.6  C107.5,18,107.4,18.1,107.3,18.1z"
    />
    <path
      class="text"
      d="M79.3,24.7c-0.1,0-0.2,0-0.3-0.1l-11-11c-0.2-0.2-0.2-0.5,0-0.6c0.2-0.2,0.5-0.2,0.6,0l11,11c0.2,0.2,0.2,0.5,0,0.6  C79.5,24.6,79.4,24.7,79.3,24.7z"
    />
    <path
      class="text"
      d="M16.2,32.3L16.2,32.3C7.3,32.3,0,25,0,16.1C0.1,7.2,7.3,0,16.2,0l0,0c4.2,0,8.4,1.7,11.4,4.7c0.5,0.5,0.5,1.4,0,1.9  s-1.4,0.5-1.9,0c-2.5-2.5-6-3.9-9.5-3.9l0,0c-7.4,0-13.4,6-13.4,13.4s6,13.5,13.4,13.5l0,0c7,0,12.7-5.3,13.4-12.1h-8.5  c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3H31c0.7,0,1.3,0.6,1.3,1.3C32.3,25,25.1,32.3,16.2,32.3z"
    />
    <path
      class="text"
      d="M53.8,32.3c-8.9,0-16.1-7.2-16.1-16.1c0-9,7.2-16.2,16.1-16.2s16.1,7.2,16.1,16.1S62.7,32.3,53.8,32.3z M53.8,2.7  c-7.4,0-13.5,6-13.5,13.5s6,13.5,13.5,13.5s13.5-6,13.5-13.5S61.2,2.7,53.8,2.7z"
    />
    <circle class="circle" cx="53.8" cy="16.1" r="3.7" />
    <path
      class="text"
      d="M39,16.6h-8c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h8c0.2,0,0.4,0.2,0.4,0.4S39.2,16.6,39,16.6z"
    />
    <path
      class="text"
      d="M163.2,17.5h-9.7c-0.7,0-1.3-0.6-1.3-1.3V1.3c0-0.7,0.6-1.3,1.3-1.3h9.7c4.8,0,8.7,3.9,8.7,8.7  C171.9,13.6,168,17.5,163.2,17.5z M154.9,14.8h8.3c3.3,0,6.1-2.7,6.1-6.1c0-3.3-2.7-6.1-6.1-6.1h-8.3V14.8z"
    />
    <path
      class="text"
      d="M165.9,32.3h-12.3c-0.7,0-1.3-0.6-1.3-1.3V16.1c0-0.7,0.6-1.3,1.3-1.3h12.3c4.8,0,8.7,3.9,8.7,8.7S170.7,32.3,165.9,32.3z   M154.9,29.6h11c3.3,0,6.1-2.7,6.1-6.1s-2.7-6.1-6.1-6.1h-11V29.6z"
    />
    <path
      class="text"
      d="M218.9,32.3c-0.7,0-1.3-0.6-1.3-1.3V1.3c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3v29.6C220.2,31.7,219.6,32.3,218.9,32.3z"
    />
    <path
      class="text"
      d="M238.6,32.3c-0.3,0-0.7-0.1-0.9-0.4l-14.8-14.8c-0.5-0.5-0.5-1.4,0-1.9s1.4-0.5,1.9,0L239.6,30c0.5,0.5,0.5,1.4,0,1.9  C239.3,32.1,239,32.3,238.6,32.3z"
    />
    <path
      class="text"
      d="M228.5,17.5h-9.7c-0.7,0-1.3-0.6-1.3-1.3V1.3c0-0.7,0.6-1.3,1.3-1.3h9.7c4.8,0,8.7,3.9,8.7,8.7S233.4,17.5,228.5,17.5z   M220.2,14.8h8.3c3.3,0,6.1-2.7,6.1-6.1s-2.7-6.1-6.1-6.1h-8.3V14.8z"
    />
    <path
      class="text"
      d="M181.3,32.3c-0.2,0-0.4,0-0.6-0.1c-0.7-0.3-0.9-1.1-0.6-1.8l14.8-29.6c0.2-0.5,0.7-0.7,1.2-0.7s1,0.3,1.2,0.7l14.8,29.6  c0.3,0.7,0.1,1.5-0.6,1.8c-0.7,0.3-1.5,0.1-1.8-0.6L196.1,4.3l-13.6,27.2C182.3,32,181.8,32.3,181.3,32.3z"
    />
    <circle class="circle" cx="196.1" cy="23.5" r="3.7" />
    <path
      class="text"
      d="M185,24h-11.7c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4H185c0.2,0,0.4,0.2,0.4,0.4S185.2,24,185,24z"
    />
    <path
      class="text"
      d="M218.9,9.2h-19.1c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h19.1c0.2,0,0.4,0.2,0.4,0.4S219.1,9.2,218.9,9.2z"
    />
  </svg>
</div>
