import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'trustHtml', pure: true })
export class TrustHtmlPipe implements PipeTransform {
  public constructor(private readonly _domSanitizer: DomSanitizer) {}

  public transform(content: string): SafeHtml {
    return this._domSanitizer.bypassSecurityTrustHtml(content);
  }
}
