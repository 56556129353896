// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';
import { CreateExport } from './dto/create-export.dto';
import { UsedResponse } from './dto/user-response.dto';

export class PortModel {
  static used(type: string, id: string): Promise<UsedResponse[]> {
    return wsc.execute('/ws/port/used', type, id) as any;
  }

  static import(
    resourceUrl: string,
    botId: number = null,
    actions: {
      type: string;
      originalId: string | number;
      importId: string | number;
      action: 'RENAME' | 'OVERWRITE' | 'USE_ORIGIN' | 'MERGE';
      name?: string;
    }[] = [],
  ): Observable<number> {
    return wsc.subscribe('/ws/port/import', resourceUrl, botId, actions) as any;
  }

  static create(data: CreateExport): Promise<{ id: number; url: string }> {
    return wsc.execute('/ws/port/create', data) as any;
  }

  static publish(): Promise<any> {
    return wsc.execute('/ws/port/publish') as any;
  }

  static my(): Promise<any> {
    return wsc.execute('/ws/port/my') as any;
  }

  static fetch(url: string): Promise<any> {
    return wsc.execute('/ws/port/fetch', url) as any;
  }

  static fetchName(type: string, id: string): Promise<any> {
    return wsc.execute('/ws/port/fetchName', type, id) as any;
  }
}
// b82c5efe1579586b75ccb0fd618b79e3a6f96982d7df1f8437d837a59afbfff3
