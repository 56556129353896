// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { TagDto } from './dto/tag.dto';

export class TagModel {
  /** 建立標籤 */
  static create(tag: TagDto): Promise<number> {
    return wsc.execute('/ws/tag/create', tag) as any;
  }

  static get(id: number): Promise<TagDto> {
    return wsc.execute('/ws/tag/get', id) as any;
  }

  /** 讀取所有標籤 */
  static getAll(): Promise<TagDto[]> {
    return wsc.execute('/ws/tag/getAll') as any;
  }

  /** 更新 */
  static update(
    /** 標籤編號 */
    id: number,
    /** 更新項目 */
    tag: TagDto,
  ): Promise<any> {
    return wsc.execute('/ws/tag/update', id, tag) as any;
  }

  /** 刪除特定標籤 */
  static delete(
    /** 標籤編號 */
    id: number,
  ): Promise<any> {
    return wsc.execute('/ws/tag/delete', id) as any;
  }
}
// e8b26181ff457b0e53725092a20acd376290258f93f29e09ccc68c7217ef25d2
