<mat-icon>done</mat-icon>

<h3>{{ title }}</h3>

<div class="content">{{ content }}</div>

<div class="buttons">
  <button mat-raised-button color="primary" (click)="close()">
    {{ confirm }}
  </button>
</div>
