export function autoRename(name: string) {
  let match = /(.*[^\d])(\d+)$/.exec(name);

  if (match) {
    name = match[1] + (parseInt(match[2], 10) + 1);
  } else {
    name = name + '_1';
  }

  return name;
}
