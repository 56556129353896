import { ensureIsArray, ExposedPromise } from '@ay/util';
import { __Column } from '@ay-gosu/server-shared';

export abstract class CacheableService<T extends __Column> {
  protected _cached: { [key: string]: Promise<T> } = {};

  protected abstract _get(ids: number[]): Promise<T[]>;

  public async getMulti(ids: number[], force: boolean = false): Promise<T[]> {
    ids = ensureIsArray(ids);

    if (force) {
      ids.map((id) => delete this._cached[id]);
    }

    let exposedPromise = new ExposedPromise();
    let need = [];
    let response = [];

    for (let id of ids) {
      if (this.hasCached(id) === undefined) {
        this._cached[id] = exposedPromise.promise.then((rows) =>
          rows.find((row) => row.id == id),
        );
        need.push(id);
      }
      response.push(this._cached[id]);
    }

    if (need.length) {
      let data = await this._get(need);
      exposedPromise.resolve(data);
    }

    return Promise.all(response);
  }

  public hasCached(id: number) {
    return this._cached[id];
  }

  public async get(id: number, force: boolean = false) {
    let rows = await this.getMulti([id], force);
    return rows[0];
  }

  public clear(id: number) {
    delete this._cached[id];
  }

  public clearAll() {
    this._cached = {};
  }
}
