import { Injectable } from '@angular/core';
import {
  PropertyConfigDto,
  PropertyConfigModel,
  PropertyConfigTargetType,
} from '@ay-gosu/server-shared';
import { Map } from '@ay/util';
import { shareReplay } from 'rxjs/operators';
import { filterList } from '../../util/filter-list';
import { ReadableError } from '../../util/readable-error';
import { sortList } from '../../util/sort-list';
import { PreloadService } from './preload.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class PropertyConfigService extends PreloadService<PropertyConfigDto> {
  public profile$ = this.all$.pipe(
    filterList((config) => config.targetType === 'profile'),
    sortList((a, b) => a.order - b.order),
    shareReplay(1),
  );

  public profileDisplayInList$ = this.profile$.pipe(
    filterList((config) => config.displayInList),
    shareReplay(1),
  );

  public profileDisplayInDetail$ = this.profile$.pipe(
    filterList((config) => config.displayInDetail),
    shareReplay(1),
  );

  public bot$ = this.all$.pipe(
    filterList((config) => config.targetType === 'bot'),
    sortList((a, b) => a.order - b.order),
    shareReplay(1),
  );

  public company$ = this.all$.pipe(
    filterList((config) => config.targetType === 'company'),
    sortList((a, b) => a.order - b.order),
    shareReplay(1),
  );

  public package$ = this.all$.pipe(
    filterList((config) => config.targetType === 'package'),
    sortList((a, b) => a.order - b.order),
    shareReplay(1),
  );

  public constructor(protected override _tokenService: TokenService) {
    super(_tokenService);
  }

  public getObservableByType(type: PropertyConfigTargetType) {
    switch (type) {
      case 'profile':
        return this.profile$;

      case 'bot':
        return this.bot$;

      case 'company':
        return this.company$;

      case 'package':
        return this.package$;
    }
  }

  public async load() {
    return PropertyConfigModel.getAll();
  }

  protected get(id: number): Promise<PropertyConfigDto> {
    return PropertyConfigModel.get(id);
  }

  public async create(config: PropertyConfigDto): Promise<number> {
    try {
      let id = await PropertyConfigModel.create(config);
      await this.afterCreate(id);
      return id;
    } catch (error) {
      throw new ReadableError($localize`新增屬性定義時發生錯誤`, {
        error,
        config,
      });
    }
  }

  public async update(config: PropertyConfigDto): Promise<boolean> {
    if (!config.id) {
      throw new ReadableError(
        $localize`更新屬性定義時發生錯誤，沒有指定的編號`,
        {
          config,
        },
      );
    }

    try {
      await PropertyConfigModel.update(config.id, config);
      await this.afterUpdate(config.id);
      return true;
    } catch (error) {
      throw new ReadableError($localize`儲存屬性定義時發生錯誤`, {
        error,
        config,
      });
    }
  }

  public async delete(id: number): Promise<void> {
    let response = await PropertyConfigModel.remove(id);
    if (response === false) throw Error();
    await this.afterDelete(id);
  }
}

export class UnknownPropertyError extends ReadableError {
  public constructor(
    key: string,
    properties: PropertyConfigDto[] | Map<PropertyConfigDto>,
  ) {
    super($localize`未知的屬性'${key}'`, { properties, key });
  }
}

export class DeleteFailedError extends ReadableError {
  public constructor(id: number) {
    super($localize`刪除屬性失敗'${id}'`);
  }
}
