import { Pipe, PipeTransform } from '@angular/core';
import { ensureIsArray } from '@ay/util';

export const ProfileTypeMap = {
  User: $localize`好友`,
  Group: $localize`群組`,
  Room: $localize`聊天室`,
};

export type ProfileType = 'User' | 'Group' | 'Room';

export const ProfileTypes: ProfileType[] = ['User', 'Group', 'Room'];

@Pipe({ name: 'profileType', pure: true })
export class ProfileTypePipe implements PipeTransform {
  public transform(types: any): any {
    types = ensureIsArray(types);
    return types
      .map((type) => ProfileTypeMap[type])
      .filter((type) => type)
      .join('、');
  }
}
