import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { BasicDialog } from './dialog/basic';
import { PageService } from './service/page.service';
import { TokenService } from './service/token.service';

@Injectable()
export class VerifyToken implements CanActivate {
  public tempRouterUrlState = '';

  public constructor(
    protected readonly _tokenService: TokenService,
    protected readonly _router: Router,
    protected readonly _basicDialog: BasicDialog,
    protected readonly _pageService: PageService,
  ) {}

  public async navigateTempUrlState(): Promise<void> {
    if (!this.tempRouterUrlState) {
      this._router.navigate([]);
      return;
    }

    if (!this.tempRouterUrlState.includes('?')) {
      this._router.navigate([this.tempRouterUrlState]);
      return;
    }

    let urls = this.tempRouterUrlState.split('?');
    let query = urls.pop().trim();

    if (!query) {
      this._router.navigate([this.tempRouterUrlState]);
      return;
    }

    let queryParams = {};
    query
      .split('&')
      .filter((q) => !!q)
      .forEach((data) => {
        let p = data.split('=');
        let key = p[0];
        let value = p[1];
        queryParams[key] = value;
      });

    this._router.navigate([urls[0]], { queryParams });
  }

  public async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    this.tempRouterUrlState = this.calcRouterUrlStatus(state);

    const isLogged = await this._tokenService.isLoggedIn();

    if (!isLogged) {
      this._router.navigate(['login']);
      return false;
    }

    return true;
  }

  private calcRouterUrlStatus(state: RouterStateSnapshot): string {
    return state.url.split('/').slice(0, 2).join('/');
  }
}
