// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { ResourceField } from './dto/field.dto';
import { ResourceDto } from './dto/resource.dto';

export class ResourceModel {
  static create(
    /** 外部資源的識別碼 */
    key: string,
    /** 外部資源的名稱 */
    name: string,
    /** 類型 */
    type?: 'csv' | 'api',
  ): Promise<number> {
    return wsc.execute('/ws/resource/create', key, name, type) as any;
  }

  static update(
    id: number,
    key?: string,
    name?: string,
    token?: boolean,
    fields?: ResourceField,
    /** 類型 */
    type?: 'csv' | 'api',
    /** api 網址 */
    url?: string,
    /** api method */
    method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'HEAD',
    /** api 的存取類型 */
    apiType?: 'schedule' | 'realtime',
    /** schedule （apiType 為 schedule 才會出現） */
    schedule?: string,
    /** 快取秒數 （apiType 為 realtime 才會出現） */
    cache?: number,
    /** api 參數 */
    properties?: { name: string; key: string; default: string }[],
    /** api header */
    headers?: { [key: string]: string },
    /** api GET method 設定 */
    get?: { [key: string]: string },
    /** api POST method 設定 */
    post?: { [key: string]: string },
  ): Promise<boolean> {
    return wsc.execute(
      '/ws/resource/update',
      id,
      key,
      name,
      token,
      fields,
      type,
      url,
      method,
      apiType,
      schedule,
      cache,
      properties,
      headers,
      get,
      post,
    ) as any;
  }

  static list(): Promise<ResourceDto[]> {
    return wsc.execute('/ws/resource/list') as any;
  }

  static remove(id: number): Promise<boolean> {
    return wsc.execute('/ws/resource/remove', id) as any;
  }

  static exec(id: number, customizeProperties: any = {}): Promise<any> {
    return wsc.execute('/ws/resource/exec', id, customizeProperties) as any;
  }

  static get(id: number): Promise<ResourceDto> {
    return wsc.execute('/ws/resource/get', id) as any;
  }
}
// 1dde6eafca1c156c48f3fc9d7eb92ef4c489bcdb4a02d547ab7c2c0e7e01863d
