// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { KeywordColumn } from './dto/keyword.dto';

export class KeywordModel {
  static getKeywordsById(id: number): Promise<KeywordColumn> {
    return wsc.execute('/ws/keyword/getKeywordsById', id) as any;
  }

  static getKeywordsByMainTagId(mainTagId: number): Promise<KeywordColumn[]> {
    return wsc.execute('/ws/keyword/getKeywordsByMainTagId', mainTagId) as any;
  }

  static getKeywordList(): Promise<KeywordColumn[]> {
    return wsc.execute('/ws/keyword/getKeywordList') as any;
  }

  static update(
    id: number,
    option: {
      keyword?: string;
      mainTagId?: number;
      synonyms?: string;
    },
  ): Promise<boolean> {
    return wsc.execute('/ws/keyword/update', id, option) as any;
  }

  static create(keyword: string, id: number, synonyms = ''): Promise<number> {
    return wsc.execute('/ws/keyword/create', keyword, id, synonyms) as any;
  }

  static delete(id: number): Promise<boolean> {
    return wsc.execute('/ws/keyword/delete', id) as any;
  }
}
// a09276334a0052d319116d50580b288a7c5dd3577b96bdb77140472baab578f0
