import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { createRandomString } from '@ay/util';
import { comparisonString } from '../../../../util/validators/comparison-string';

const CHECK_CODE_LENGTH = 4;
const CHECK_CODE_CHARS = 'ABCDEFGHJKMNPQRSTUVWXYZ23456789';

@Component({
  selector: 'dl-delete-confirm-dialog',
  templateUrl: './delete-confirm.dialog.html',
  styleUrls: ['./delete-confirm.dialog.scss'],
})
export class DeleteConfirmDialog {
  @Input()
  public name = '';

  public checkCode = this.createCheckCode();

  public checkCodeInputControl = new FormControl(
    '',
    Validators.compose([
      (control: FormControl) =>
        comparisonString(control, this.checkCode, { ignoreCase: true }),
    ]),
  );

  public form = new FormGroup({ checkCode: this.checkCodeInputControl });

  public constructor(public dialogRef: MatDialogRef<DeleteConfirmDialog>) {}

  public createCheckCode() {
    return createRandomString(CHECK_CODE_CHARS, CHECK_CODE_LENGTH);
  }

  public onSubmit() {
    this.dialogRef.close(true);
  }
}
