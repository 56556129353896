// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { PackageSendImmediately } from '../package/dto/send-immediately.type';
import { PackageSendProcess } from '../package/dto/send-process.type';
import { wsc } from '../wsc';
import {
  CreateBoxfulTicketDto,
  CreateCodeTicketDto,
  CreateFeverSocialTicketDto,
  ListBoxfulTicketRecordResponseDto,
  ListCodeTicketRecordResponseDto,
  ListFeverSocialTicketRecordResponseDto,
  ListProfileTicketRecordResponseDto,
  ListTicketRecordResponseDto,
  TicketListResponseBoxfulRowDto,
  TicketListResponseCodeRowDto,
  TicketListResponseDto,
  TicketListResponseFeverSocialRowDto,
  UpdateBoxfulTicketDto,
  UpdateCodeDto,
  UpdateFeverSocialTicketDto,
  UpdateTicketDto,
} from '@ay-gosu/types/ticket-machine';
import { FetchCodeDto } from '@ay-gosu/types';
import { Observable } from 'rxjs';

export class TicketMachineModel {
  static create(
    data:
      | CreateCodeTicketDto
      | CreateBoxfulTicketDto
      | CreateFeverSocialTicketDto,
  ): Promise<number> {
    return wsc.execute('/ws/ticket-machine/create', data) as any;
  }

  static list(): Promise<TicketListResponseDto> {
    return wsc.execute('/ws/ticket-machine/list') as any;
  }

  static fetch(
    id: number,
  ): Promise<
    | TicketListResponseCodeRowDto
    | TicketListResponseBoxfulRowDto
    | TicketListResponseFeverSocialRowDto
  > {
    return wsc.execute('/ws/ticket-machine/fetch', id) as any;
  }

  static fetchCodes(
    ticketId: number,
    page: number,
    count: number,
  ): Promise<FetchCodeDto> {
    return wsc.execute(
      '/ws/ticket-machine/fetchCodes',
      ticketId,
      page,
      count,
    ) as any;
  }

  static update(
    id: number,
    data: UpdateBoxfulTicketDto | UpdateFeverSocialTicketDto | UpdateTicketDto,
  ): Promise<any> {
    return wsc.execute('/ws/ticket-machine/update', id, data) as any;
  }

  static createCodes(
    id: number,
    codes: { url: string; code: string }[],
  ): Promise<{
    success: number;
    fail: number;
  }> {
    return wsc.execute('/ws/ticket-machine/createCodes', id, codes) as any;
  }

  static delete(id: number): Promise<void> {
    return wsc.execute('/ws/ticket-machine/delete', id) as any;
  }

  static deleteCodes(ticketId: number, codeIds: number[]): Promise<void> {
    return wsc.execute(
      '/ws/ticket-machine/deleteCodes',
      ticketId,
      codeIds,
    ) as any;
  }

  static assignCode(
    ticketId: number,
    ticketCodeId: number,
    profileId: number,
  ): Promise<void> {
    return wsc.execute(
      '/ws/ticket-machine/assignCode',
      ticketId,
      ticketCodeId,
      profileId,
    ) as any;
  }

  static updateCode(ticketCodeId: number, data: UpdateCodeDto): Promise<void> {
    return wsc.execute(
      '/ws/ticket-machine/updateCode',
      ticketCodeId,
      data,
    ) as any;
  }

  static fetchTicketListByProfileId(
    profileId: number,
  ): Promise<ListProfileTicketRecordResponseDto> {
    return wsc.execute(
      '/ws/ticket-machine/fetchTicketListByProfileId',
      profileId,
    ) as any;
  }

  static fetchRecords(
    ticketId: number,
    option: {
      page?: number;
      pageSize?: number;
      // -1 = 未領獎
      // 其他 = 對應的獎品編號
      prizeFilter?: number;
    } = {},
  ): Promise<ListTicketRecordResponseDto> {
    return wsc.execute(
      '/ws/ticket-machine/fetchRecords',
      ticketId,
      option,
    ) as any;
  }

  static fetchRecord(ticketRecordId: number): Promise<
    {
      id: number;
      ticket: {
        id: number;
        name: string;
        type: 'CODE' | 'BOXFUL' | 'FEVER_SOCIAL';
      };
    } & (
      | ListCodeTicketRecordResponseDto
      | ListBoxfulTicketRecordResponseDto
      | ListFeverSocialTicketRecordResponseDto
    )
  > {
    return wsc.execute('/ws/ticket-machine/fetchRecord', ticketRecordId) as any;
  }

  static multipleAssign(
    packageId: number,
    data: PackageSendImmediately,
    ticketId: number,
  ): Observable<PackageSendProcess> {
    return wsc.subscribe(
      '/ws/ticket-machine/multipleAssign',
      packageId,
      data,
      ticketId,
    ) as any;
  }

  static resendTicketMessage(
    packageId: number,
    data: { profileId: number; code: string; url: string },
  ): Observable<PackageSendProcess> {
    return wsc.subscribe(
      '/ws/ticket-machine/resendTicketMessage',
      packageId,
      data,
    ) as any;
  }
}
// b324553d32fc4f1ab4afe37a5325002d2be51210a49c34997c0d149eb4cb073a
