// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { GroupObject } from './dto/group-object.dto';
import { GroupColumn } from './dto/group.dto';
import { FilterListResult } from './dto/list-result.dto';
import { FilterMode } from './dto/mode.type';

export class FilterModel {
  static filter(
    group: GroupObject,
    type: FilterMode = 'PROFILE',
    order: 'DESC' | 'ASC' = 'ASC',
  ): Promise<{ token: string; count: number }> {
    return wsc.execute('/ws/filter/filter', group, type, order) as any;
  }

  static getFilterResult(
    token: string,
    page: number,
    perCount: number,
  ): Promise<number[]> {
    return wsc.execute(
      '/ws/filter/getFilterResult',
      token,
      page,
      perCount,
    ) as any;
  }

  static keepAlive(token: string): Promise<void> {
    return wsc.execute('/ws/filter/keepAlive', token) as any;
  }

  static isAlive(token: string): Promise<boolean> {
    return wsc.execute('/ws/filter/isAlive', token) as any;
  }

  static create(group: GroupColumn): Promise<number> {
    return wsc.execute('/ws/filter/create', group) as any;
  }

  static getList(): Promise<FilterListResult[]> {
    return wsc.execute('/ws/filter/getList') as any;
  }

  static getDetail(id: number): Promise<GroupColumn> {
    return wsc.execute('/ws/filter/getDetail', id) as any;
  }

  static update(id: number, group: GroupColumn): Promise<boolean> {
    return wsc.execute('/ws/filter/update', id, group) as any;
  }

  static rename(id: number, name: string): Promise<void> {
    return wsc.execute('/ws/filter/rename', id, name) as any;
  }

  static delete(id: number): Promise<boolean> {
    return wsc.execute('/ws/filter/delete', id) as any;
  }
}
// 91fc984b1e066d106ff892f34c7a471a58feadc672932c5ced003f110d916f82
