import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MarketPlaceGuard } from '../market-place.guard';
import { BasicDialogModule } from './dialog/basic';
import { DirectusComponent } from './pages/directus/directus.component';
import { LandingPageComponent } from './pages/landing/landing.page';
import { SaveReminder } from './save-reminder';
import { VerifyToken } from './verify-token';
import { VerifyTokenAndAuth } from './verify-token-and-auth';

const routes: Routes = [
  {
    path: 'policy',
    loadChildren: () =>
      import('./pages/policy/policy.module').then((m) => m.PolicyModule),
  },
  {
    path: 'sso',
    loadChildren: () =>
      import('./pages/sso/sso.module').then((m) => m.SsoModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'market-place',
    loadChildren: () =>
      import('./pages/market-place/market-place.module').then(
        (m) => m.MarketPlaceModule,
      ),
    canActivate: [MarketPlaceGuard],
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentModule),
    canActivate: [VerifyToken],
    data: { disableAuthCheck: true },
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./pages/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule,
      ),
  },
  {
    path: 'broadcast',
    loadChildren: () =>
      import('./pages/broadcast/broadcast.module').then(
        (m) => m.BroadcastModule,
      ),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },
  {
    path: 'private-message',
    loadChildren: () =>
      import('./pages/private-message/private-message.module').then(
        (m) => m.PrivateMessageModule,
      ),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },
  {
    path: 'friends',
    loadChildren: () =>
      import('./pages/friends/friends.module').then((m) => m.FriendsModule),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },
  {
    path: 'filter',
    loadChildren: () =>
      import('./pages/filter/filter.module').then((m) => m.FilterModule),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'tag',
    loadChildren: () =>
      import('./pages/tag/tag.module').then((m) => m.TagModule),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'property',
    loadChildren: () =>
      import('./pages/property/property.module').then((m) => m.PropertyModule),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'flow',
    loadChildren: () =>
      import('./pages/flow/flow.module').then((m) => m.FlowModule),
    canActivate: [VerifyTokenAndAuth],
    canDeactivate: [SaveReminder],
  },

  {
    path: 'resource',
    loadChildren: () =>
      import('./pages/resource/resource.module').then((m) => m.ResourceModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'keyword',
    loadChildren: () =>
      import('./pages/keyword/keyword.module').then((m) => m.KeywordModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'imaging',
    loadChildren: () =>
      import('./pages/imaging/imaging.module').then((m) => m.ImagingModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'richmenu',
    loadChildren: () =>
      import('./pages/rich-menu/rich-menu.module').then(
        (m) => m.RichMenuModule,
      ),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'bot',
    loadChildren: () =>
      import('./pages/bot/bot.module').then((m) => m.BotModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'company',
    loadChildren: () =>
      import('./pages/company/company.module').then((m) => m.CompanyModule),
    canActivate: [VerifyToken],
  },

  {
    path: 'chart',
    loadChildren: () =>
      import('./pages/chart/chart.module').then((m) => m.ChartModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'dynamic-form',
    redirectTo: 'form',
  },
  {
    path: 'form',
    loadChildren: () =>
      import('./pages/dynamic-form/dynamic-form.module').then(
        (m) => m.DynamicFormModule,
      ),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'coupon',
    loadChildren: () =>
      import('./pages/coupon/coupon.module').then((m) => m.CouponModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'exchange',
    loadChildren: () =>
      import('./pages/exchange/exchange.module').then((m) => m.ExchangeModule),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'ticket',
    loadChildren: () =>
      import('./pages/ticket-event/ticket-event.module').then(
        (m) => m.TicketEventModule,
      ),
    canActivate: [VerifyTokenAndAuth],
  },

  {
    path: 'directus',
    component: DirectusComponent,
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'log',
    loadChildren: () =>
      import('./pages/log/log.module').then((m) => m.LogModule),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'store',
    loadChildren: () =>
      import('./pages/store/store.module').then((m) => m.StoreModule),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'promotion-channel',
    loadChildren: () =>
      import('./pages/promotion-channel/promotion-channel.module').then(
        (m) => m.PromotionChannelModule,
      ),
    canActivate: [VerifyTokenAndAuth],
  },
  {
    path: 'embedded-private-message',
    loadChildren: () =>
      import(
        './pages/embedded-private-message/embedded-private-message.module'
      ).then((m) => m.EmbeddedPrivateMessageModule),
  },
  {
    path: '**',
    component: LandingPageComponent,
    canActivate: [VerifyToken],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }),
    BasicDialogModule,
  ],
  exports: [RouterModule],
  providers: [VerifyTokenAndAuth, VerifyToken, SaveReminder],
})
export class AppRoutingModule {}
