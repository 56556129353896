// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';

export class ModuleChannelModel {
  static fetchAuthorizationUrl(
    type = 'GOSU BAR',
    redirectUri?: string,
    basicSearchId?: string,
  ): Promise<string> {
    return wsc.execute(
      '/ws/module-channel/fetchAuthorizationUrl',
      type,
      redirectUri,
      basicSearchId,
    ) as any;
  }

  static attach(state: string, code: string): Promise<string> {
    return wsc.execute('/ws/module-channel/attach', state, code) as any;
  }
}
// 3abd77f2465b8371033969e7bc9fb38078e9d890703d2c5e4ff0020b4c7c6959
