<h2 *ngIf="data.title">{{ data.title }}</h2>

<p *ngIf="data.content">{{ data.content }}</p>

<div class="buttons">
  <button mat-button [mat-dialog-close]="false" tabindex="2">
    <ng-container *ngIf="data?.cancel; else cancelBlock"
      >{{data.cancel}}</ng-container
    >
    <ng-template #cancelBlock i18n>取消</ng-template>
  </button>
  <button mat-button color="primary" [mat-dialog-close]="true" cdkFocusInitial>
    <ng-container *ngIf="data?.ok; else okBlock">{{data.ok}}</ng-container>
    <ng-template #okBlock i18n>確定</ng-template>
  </button>
</div>
