import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountModel, CompanyDto } from '@ay-gosu/server-shared';
import { of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  mergeMap,
  shareReplay,
  takeUntil,
} from 'rxjs/operators';
import { BasicDialog } from '../../../dialog/basic';
import { TokenService } from '../../../service/token.service';
import { LoginService } from '../login.service';

@Component({
  selector: 'gosu-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.dialog]': 'dialogData',
  },
})
export class SelectCompanyComponent implements OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  private _redirectToLoginWhenNotLoggedIn = this.tokenService.token$
    .pipe(
      filter((token) => !token),
      takeUntil(this._destroy$),
    )
    .subscribe((token) => this._router.navigate(['login', 'form']));

  public companies$ = this.tokenService.accountWithoutCompany$.pipe(
    mergeMap((account) => (account ? AccountModel.getCompanyList() : [])),
    catchError(() => of([])),
    shareReplay(1),
    takeUntil(this._destroy$),
  );

  private _isSelectCompanyPage$ = this._activatedRoute.url.pipe(
    filter((urls) => !!urls.find((url) => url.path === 'select-company')),
  );

  private _redirectToLandingPageWhenSelected = this._isSelectCompanyPage$
    .pipe(
      mergeMap((_) => this.tokenService.account$),
      filter((account) => !!account),
      takeUntil(this._destroy$),
    )
    .subscribe((token) => this._router.navigateByUrl('/'));

  private _redirectToCreateCompanyWhenNotCompany = this._isSelectCompanyPage$
    .pipe(
      mergeMap((_) => this.companies$),
      filter((companies) => companies.length === 0),
      takeUntil(this._destroy$),
    )
    .subscribe((_) => this._router.navigate(['login', 'create-company']));

  private _autoSelectCompanyWhenOnlyOneCompany = this._isSelectCompanyPage$
    .pipe(
      mergeMap((_) => this.companies$),
      filter((companies) => companies.length === 1),
      takeUntil(this._destroy$),
    )
    .subscribe(async (companies) => {
      await this.tokenService.selectCompany(companies[0].id);
      this._router.navigateByUrl('/');
    });

  public ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  public constructor(
    public readonly tokenService: TokenService,
    private readonly _loginService: LoginService,
    private readonly _router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _matSnackBar: MatSnackBar,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public readonly dialogData: { isDialog: boolean },
    @Optional()
    private readonly _matDialogRef: MatDialogRef<any>,
    private readonly _basicDialog: BasicDialog,
  ) {}

  public async selectCompany(company: CompanyDto): Promise<void> {
    this._loginService.isLoading$.next(true);
    try {
      await this.tokenService.selectCompany(company.id);
      if (this._matDialogRef) {
        this._matDialogRef.close();
      }
      this._router.navigate(['']);
      this._matSnackBar.open($localize`成功選擇組織 ` + company.name);
    } catch (err) {
      this._basicDialog.resetPassword();
      return;
    }
    this._loginService.isLoading$.next(false);
  }

  public createCompany() {
    if (this._matDialogRef) {
      this._matDialogRef.close();
    }
    this._router.navigateByUrl('/login/create-company');
  }
}
