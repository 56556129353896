// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';
import { CompanyOverviewDto } from './dto/company-overview.dto';
import { AccountFetchInfoDto } from './dto/fetch-info.dto';
import { FetchLineUserInfoDto } from './dto/fetch-line-user-info.dto';
import { AccountFetchOverviewDto } from './dto/fetch-overview.dto';
import { LineLoginDto } from './dto/line-login.dto';

export class AccountModel {
  static getOverview(): Promise<AccountFetchOverviewDto[]> {
    return wsc.execute('/ws/account/getOverview') as any;
  }

  // 註冊帳號
  static register(
    name: string,
    email: string,
    phone: string,
    password: string,
  ): Promise<number> {
    return wsc.execute(
      '/ws/account/register',
      name,
      email,
      phone,
      password,
    ) as any;
  }

  static login(email: string, password: string): Promise<string> {
    return wsc.execute('/ws/account/login', email, password) as any;
  }

  static loginViaToken(token: string): Promise<string> {
    return wsc.execute('/ws/account/loginViaToken', token) as any;
  }

  static loginViaEmbeddedPrivateMessageToken(
    token: string,
  ): Promise<{ profileId: number; accountId: number; token: string }> {
    return wsc.execute(
      '/ws/account/loginViaEmbeddedPrivateMessageToken',
      token,
    ) as any;
  }

  static refresh(): Promise<string> {
    return wsc.execute('/ws/account/refresh') as any;
  }

  // 取得組織清單
  static getCompanyList(): Promise<CompanyOverviewDto[]> {
    return wsc.execute('/ws/account/getCompanyList') as any;
  }

  // 選擇組織，回傳 token
  static selectCompany(companyId: number): Promise<string> {
    return wsc.execute('/ws/account/selectCompany', companyId) as any;
  }

  // 修改密碼
  static changePassword(
    oldPassword: string,
    newPassword: string,
  ): Promise<any> {
    return wsc.execute(
      '/ws/account/changePassword',
      oldPassword,
      newPassword,
    ) as any;
  }

  // 檢查是否已經登入
  static isLoggedIn(): Promise<boolean> {
    return wsc.execute('/ws/account/isLoggedIn') as any;
  }

  // 更新帳號資料
  static update(values: { name?: string }): Promise<boolean> {
    return wsc.execute('/ws/account/update', values) as any;
  }

  // 取得帳號相關資訊(個人設定頁面)
  static info(): Promise<AccountFetchInfoDto> {
    return wsc.execute('/ws/account/info') as any;
  }

  // 登出帳號
  static logout(): Promise<any> {
    return wsc.execute('/ws/account/logout') as any;
  }

  static registerViaFB(accessToken: string, name: string): Promise<string> {
    return wsc.execute('/ws/account/registerViaFB', accessToken, name) as any;
  }

  static loginViaFB(accessToken: string): Promise<string> {
    return wsc.execute('/ws/account/loginViaFB', accessToken) as any;
  }

  static bindFB(accessToken: string): Promise<any> {
    return wsc.execute('/ws/account/bindFB', accessToken) as any;
  }

  static unbindFB(): Promise<any> {
    return wsc.execute('/ws/account/unbindFB') as any;
  }

  static registerViaGoogle(accessToken: string): Promise<string> {
    return wsc.execute('/ws/account/registerViaGoogle', accessToken) as any;
  }

  static loginViaGoogle(accessToken: string): Promise<string> {
    return wsc.execute('/ws/account/loginViaGoogle', accessToken) as any;
  }

  static bindGoogle(accessToken: string): Promise<any> {
    return wsc.execute('/ws/account/bindGoogle', accessToken) as any;
  }

  static unbindGoogle(): Promise<any> {
    return wsc.execute('/ws/account/unbindGoogle') as any;
  }

  static lineLogin(): Observable<LineLoginDto> {
    return wsc.subscribe('/ws/account/lineLogin') as any;
  }

  static registerViaLine(): Promise<any> {
    return wsc.execute('/ws/account/registerViaLine') as any;
  }

  static loginViaLine(): Promise<any> {
    return wsc.execute('/ws/account/loginViaLine') as any;
  }

  static lineUserInfo(): Promise<FetchLineUserInfoDto> {
    return wsc.execute('/ws/account/lineUserInfo') as any;
  }

  static bindLine(): Promise<any> {
    return wsc.execute('/ws/account/bindLine') as any;
  }

  static unbindLine(): Promise<any> {
    return wsc.execute('/ws/account/unbindLine') as any;
  }

  static sendResetPasswordMail(account: string): Promise<boolean> {
    return wsc.execute('/ws/account/sendResetPasswordMail', account) as any;
  }

  static verifyResetPasswordToken(token: string): Promise<boolean> {
    return wsc.execute('/ws/account/verifyResetPasswordToken', token) as any;
  }

  static changePasswordByToken(token: string, password: string): Promise<true> {
    return wsc.execute(
      '/ws/account/changePasswordByToken',
      token,
      password,
    ) as any;
  }

  static sendVerifyMail(): Promise<any> {
    return wsc.execute('/ws/account/sendVerifyMail') as any;
  }
}
// a1d30b0ac1c671e5eb676219465debb8931e03df010e582486821ccc773fdc96
