import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'dl-reset-password-dialog',
  templateUrl: './reset-password.dialog.html',
  styleUrls: ['./reset-password.dialog.scss'],
})
export class ResetPasswordDialog {
  public title: string = $localize`密碼設定不安全`;
  public content: string = $localize`您目前的密碼不符合密碼規則，請重新設定`;
  public confirm: string = $localize`重設密碼`;

  public constructor(
    private _router: Router,
    private _matDialogRef: MatDialogRef<ResetPasswordDialog>,
  ) {}

  public submit() {
    this._matDialogRef.close();
    this._router.navigateByUrl('/reset-password/loggedIn');
  }
}
