// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { CompanyDto } from '../_module/dto/company.type';
import { wsc } from '../wsc';
import { CompanyRegisterUtmDto } from './dto/register-utm.dto';

export class CompanyModel {
  static fetch(id: number): Promise<CompanyDto> {
    return wsc.execute('/ws/company/fetch', id) as any;
  }

  static update(body: { name: string }): Promise<string> {
    return wsc.execute('/ws/company/update', body) as any;
  }

  static setProperties(property: {
    [key: string]: { value: string; reason?: string };
  }): Promise<any> {
    return wsc.execute('/ws/company/setProperties', property) as any;
  }

  /** 設定訊息參數 */
  static setProperty(
    /** 屬性名稱 */
    key: string,
    /** 屬性值 */
    value = '',
    /** 原因 */
    reason = '',
  ): Promise<boolean> {
    return wsc.execute('/ws/company/setProperty', key, value, reason) as any;
  }

  static getProperties(): Promise<{ [key: string]: string }> {
    return wsc.execute('/ws/company/getProperties') as any;
  }

  static getProperty(key: string): Promise<any> {
    return wsc.execute('/ws/company/getProperty', key) as any;
  }

  /** 註冊使用服務 */
  static register(
    /** 組織名稱 */
    name: string,
    type: 'GENERAL' | 'MODULE_CHANNEL' = 'GENERAL',
    utm: CompanyRegisterUtmDto = {},
  ): Promise<CompanyDto> {
    return wsc.execute('/ws/company/register', name, type, utm) as any;
  }

  static fetchCompaniesPasswordRoles(): Promise<
    {
      companyId: number;
      companyName: string;
      type: string;
      args: any;
    }[]
  > {
    return wsc.execute('/ws/company/fetchCompaniesPasswordRoles') as any;
  }
}
// 3d36fd26a7a028b570bf9c81a562218f4b21a1226851e24999c9854eebc31fbf
