import { InjectionToken } from '@angular/core';

export const COPYRIGHT = new InjectionToken<string>('COPYRIGHT');
export const LANDING = new InjectionToken<string>('LANDING');
export const TEL = new InjectionToken<string>('TEL');
export const NAME = new InjectionToken<string>('NAME');

export function partnerProvider<T>(
  token: InjectionToken<T>,
  parameter: string,
) {
  return {
    provide: token,
    useFactory: () =>
      window['partner'] ? window['partner'][parameter] : false,
  };
}

export const nameProvider = partnerProvider(NAME, 'name');
export const copyrightProvider = partnerProvider(COPYRIGHT, 'copyright');
export const landingProvider = partnerProvider(LANDING, 'landing');
export const telProvider = partnerProvider(TEL, 'tel');

export const PartnerProvider = {
  name: nameProvider,
  copyright: copyrightProvider,
  landing: landingProvider,
  tel: telProvider,
};
