import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
  public units = [
    'bit',
    'Byte',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB',
  ];

  public bases = [1, 8, 1024, 1024, 1024, 1024, 1024, 1024, 1024, 1024];

  public transform(size: number, base: string = 'bit') {
    let i = this.units.indexOf(base);

    while (size / this.bases[i + 1] >= 1) {
      i++;
      size /= this.bases[i];
    }

    return size.toFixed(2) + ' ' + this.units[i];
  }
}
