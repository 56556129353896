import { isUndefined, negate, pickBy } from 'lodash';
import 'reflect-metadata';

export class CodeError extends Error {
  public static _code = 'CODE_ERROR';

  public static _class: any;

  public constructor(message: string, public readonly httpStatus: number) {
    super(message);
    Object.setPrototypeOf(this, CodeError.prototype);
  }

  public get code() {
    return this['__proto__']['constructor']['_code'];
  }

  public static set code(code: string) {
    this._code = code;
    Object.defineProperty(this._class, 'name', {
      value: code,
      configurable: true,
    });

    this.defineMetaData('code', { type: String, example: code });
  }

  public static defineMetaData(
    propertyKey: string,
    metadata: { type: any; example: any },
  ) {
    const metadataKey = 'swagger/apiModelPropertiesArray';
    const metaKey = 'swagger/apiModelProperties';
    const target = this._class.prototype;
    const properties = Reflect.getMetadata(metadataKey, target) || [];
    const key = `:${propertyKey}`;
    if (!properties.includes(key)) {
      Reflect.defineMetadata(
        metadataKey,
        [...properties, `:${propertyKey}`],
        target,
      );
    }

    const existingMetadata = Reflect.getMetadata(metaKey, target, propertyKey);

    if (existingMetadata) {
      const newMetadata = pickBy(metadata, negate(isUndefined));
      const metadataToSave = Object.assign(
        Object.assign({}, existingMetadata),
        newMetadata,
      );
      Reflect.defineMetadata(metaKey, metadataToSave, target, propertyKey);
    } else {
      Reflect.defineMetadata(
        metaKey,
        Object.assign(
          { type: Reflect.getMetadata('design:type', target, propertyKey) },
          pickBy(metadata, negate(isUndefined)),
        ),
        target,
        propertyKey,
      );
    }
    return { metakey: metaKey, target };
  }

  public toJSON() {
    return {
      code: this.code,
      status: this.httpStatus,
      message: this.message,
    };
  }
}

export class MultipleCodeError extends CodeError {
  public static override _code = 'MULTIPLE';

  public constructor(
    protected errors: CodeError[],
    public override readonly httpStatus: number,
  ) {
    super('發生多個錯誤', httpStatus);
    Object.setPrototypeOf(this, MultipleCodeError.prototype);
  }

  public override toJSON() {
    return {
      code: this.code,
      status: this.httpStatus,
      message: this.message,
      errors: this.errors.map((error) => {
        const json = error.toJSON();
        delete json['status'];
        return json;
      }),
    };
  }
}

export function CodeErrorGenerate<T extends any[] = []>(
  message: string | ((...args: T) => string | { message: string }),
  httpStatus = 500,
  defineMeta: { [key: string]: any } = {},
) {
  const AnonymousCodeErrorClass = class extends CodeError {
    public static override code: string;

    public detail: any;

    public constructor(...args: T) {
      if (message instanceof Function) {
        const response = message(...args);
        if (response instanceof Object) {
          super(response.message, httpStatus);
        } else {
          super(response, httpStatus);
        }
        this.detail = response;
      } else {
        super(message, httpStatus);
      }
      Object.setPrototypeOf(this, AnonymousCodeErrorClass.prototype);
    }

    public override toJSON() {
      if (typeof this.detail === 'string') {
        return {
          code: this.code,
          status: this.httpStatus,
          message: this.message,
        };
      } else {
        return {
          ...this.detail,
          code: this.code,
          status: this.httpStatus,
          message: this.message,
        };
      }
    }
  };

  AnonymousCodeErrorClass._class = AnonymousCodeErrorClass;

  AnonymousCodeErrorClass.defineMetaData('code', {
    type: String,
    example: 'UNKNOWN_ERROR_CODE',
  });

  AnonymousCodeErrorClass.defineMetaData('status', {
    type: Number,
    example: httpStatus,
  });

  let messageExample = '';
  if (typeof message === 'string') {
    messageExample = message;
  } else {
    try {
      const res = message(...('XXX,'.repeat(100).split(',') as any));
      if (typeof res === 'string') {
        messageExample = res;
      } else {
        messageExample = res.message;
      }
    } catch (error) {
      messageExample = '';
    }
  }

  AnonymousCodeErrorClass.defineMetaData('message', {
    type: String,
    example: messageExample,
  });

  Object.entries(defineMeta).map(([key, data]) =>
    AnonymousCodeErrorClass.defineMetaData(key, data),
  );

  return AnonymousCodeErrorClass;
}

export class CodeErrorParser {
  protected errors: {
    [code: string]: typeof CodeError;
  } = {};

  public parse(error: {
    response: {
      status: number;
      data: {
        code: string;
        message: string;
      };
    };
  }) {
    const response = error?.response;
    const data = error?.response?.data;
    if (!data || !data.code) return;
    const code = data.code;
    if (this.errors[code] === undefined) {
      const AnonymousCodeErrorClass = class extends CodeError {
        public constructor(message: string) {
          super(message, response.status);
          Object.setPrototypeOf(this, AnonymousCodeErrorClass.prototype);
        }
      };
      this.errors[code] = AnonymousCodeErrorClass;
      this.errors[code].code = code;
    }
    throw new this.errors[code](data.message, response.status);
  }
}
