// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { UploadResponse } from '@ay-nestjs/share-client';
import { Observable } from 'rxjs';

export class StaticModel {
  /** 上傳檔案 */
  static upload(
    /** 檔案名稱 */
    filename: string,
    /** 檔案 */
    file: File,
  ): Observable<UploadResponse<string>> {
    return wsc.upload('/ws/static/upload', filename, file) as any;
  }
}
// e89771864847625025c6bffb0f51b530c208496843551bed14dcb70aaecc1d5b
