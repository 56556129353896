import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ExposedPromise } from '@ay/util';
import { delay } from 'bluebird';
import extend from 'lodash/extend';
import { filter, firstValueFrom } from 'rxjs';
import { EmptyResponseError } from '../../../util/empty-response-error';
import { MatConnectedDialogConfig } from '../../material/connected-dialog';
import { UploadAction } from '../../service/static.service';
import { DialogService } from '../dialog.service';
import { BasicDialogModule } from './basic-dialog.module';
import { ConfirmDialog } from './confirm/confirm.dialog';
import { CopyConfirmDialog } from './copy-confirm/copy-confirm.dialog';
import { DeleteConfirmDialog } from './delete-confirm/delete-confirm.dialog';
import { ErrorDialog } from './error/error.dialog';
import { InfoDialog } from './info/info.dialog';
import { LoadingDialog } from './loading/loading.dialog';
import { PromptDialog } from './prompt/prompt.dialog';
import { RenameDialog } from './rename/rename.dialog';
import { ResetPasswordDialog } from './reset-password/reset-password.dialog';
import { SuccessDialog } from './success/success.dialog';

export interface PromptConfig extends MatConnectedDialogConfig {
  placeholder?: string;
  value?: string;
  confirm?: string;
  required?: string;
}

export interface ConfirmConfig extends MatConnectedDialogConfig {
  content?: string;
  ok?: string;
  cancel?: string;
}

export interface SuccessConfig extends MatConnectedDialogConfig {
  content?: string;
  confirm?: string;
  autoCloseSecond?: number;
}

export interface InfoConfig extends MatConnectedDialogConfig {
  content?: string;
  confirm?: string;
}

@Injectable({ providedIn: BasicDialogModule })
export class BasicDialog extends DialogService {
  public async delete(name?: string, config?: MatConnectedDialogConfig) {
    const exposedPromise = new ExposedPromise();

    this._open(
      DeleteConfirmDialog,
      extend({ data: { name } }, config),
      exposedPromise,
    );

    return exposedPromise.promise;
  }

  public async copy(name: string, config?: MatConnectedDialogConfig) {
    const exposedPromise = new ExposedPromise();

    this._open(
      CopyConfirmDialog,
      extend({ data: name, panelClass: 'dialog-container-p0' }, config),
      exposedPromise,
    );

    return exposedPromise.promise;
  }

  public async rename(
    name: string,
    config?: MatConnectedDialogConfig,
  ): Promise<string> {
    const exposedPromise = new ExposedPromise();

    this._open(
      RenameDialog,
      extend({ panelClass: 'dialog-container-p0', data: { name } }, config),
      exposedPromise,
    );

    return exposedPromise.promise;
  }

  public async confirm(title: string, config: ConfirmConfig = {}) {
    const exposedPromise = new ExposedPromise();

    const dialogRef = this._open(
      ConfirmDialog,
      extend(
        {
          data: {
            title,
            content: config.content,
            ok: config.ok,
            cancel: config.cancel,
          },
        },
        config,
      ),
      exposedPromise,
    );

    const instance = dialogRef.componentInstance;

    return exposedPromise.promise.catch((err) => false);
  }

  public prompt(title: string, config: PromptConfig = {}) {
    const exposedPromise = new ExposedPromise();

    const dialogRef = this._open(
      PromptDialog,
      extend({}, config, { panelClass: 'dialog-container-p0' }),
      exposedPromise,
    );

    const instance = dialogRef.componentInstance;
    instance.title = title;

    if (config.placeholder) {
      instance.placeholder = config.placeholder;
    }

    if (config.value) {
      instance.value = config.value;
    }

    if (config.confirm) {
      instance.confirm = config.confirm;
    }

    if (config.required) {
      instance.required = config.required;
    }

    return exposedPromise.promise;
  }

  public success(title: string, config: SuccessConfig = {}) {
    const exposedPromise = new ExposedPromise();
    const dialogRef = this._open(SuccessDialog, config, exposedPromise);
    const instance = dialogRef.componentInstance;

    instance.title = title;

    if (config.confirm) {
      instance.confirm = config.confirm;
    }

    if (config.content) {
      instance.content = config.content;
    }

    if (config.autoCloseSecond) {
      delay(config.autoCloseSecond * 1000).then((e) => dialogRef.close());
    }

    return exposedPromise.promise.catch((e) => null);
  }

  public error(
    content: string,
    config?: MatConnectedDialogConfig & {
      buttons?: {
        label: string;
        type?: string;
        color?: string;
        result?: string;
      }[];
    },
  ) {
    const exposedPromise = new ExposedPromise();
    const dialogRef = this._open(ErrorDialog, config, exposedPromise);
    const instance = dialogRef.componentInstance;

    instance.buttons = config?.buttons;
    instance.content = content;

    return exposedPromise.promise.catch((e) => null);
  }

  public info(content: string, config: InfoConfig = {}) {
    const exposedPromise = new ExposedPromise();
    const dialogRef = this._open(InfoDialog, config, exposedPromise);
    const instance = dialogRef.componentInstance;

    instance.content = content;

    return exposedPromise.promise.catch((e) => null);
  }

  public loading(
    text: string,
    config: MatConnectedDialogConfig = {},
  ): MatDialogRef<LoadingDialog> {
    const exposedPromise = new ExposedPromise();

    const dialogRef = this._open(
      LoadingDialog,
      extend({ disableClose: true }, config),
      exposedPromise,
    );

    // 忽略 EmptyResponseError
    exposedPromise.promise.catch((error) => {
      if (error instanceof EmptyResponseError) return null;
      else throw error;
    });

    const instance = dialogRef.componentInstance;

    instance.text = text;

    return dialogRef;
  }

  public resetPassword() {
    this.connectedDialog.open(ResetPasswordDialog);
  }

  public async uploadProgressing<Result = any>(
    task: UploadAction<Result>,
  ): Promise<Result> {
    const dialogRef = this.loading('檔案上傳中');
    const dialog = dialogRef.componentInstance;
    dialog.color = 'primary';
    dialog.mode = 'determinate';
    dialog.value = 0;
    task.progress.subscribe((progress) => (dialog.value = progress * 100));
    const response = await firstValueFrom(
      task.uploadSubject.pipe(
        filter((response) => response && response.status == 'all-done'),
      ),
    );
    dialogRef.close();
    return response.result;
  }
}
