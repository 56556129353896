<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="container">
    <p>{{ title }}</p>

    <mat-form-field class="fw">
      <input matInput [placeholder]="placeholder" formControlName="value" />
      <mat-error
        *ngIf="valueInputControl.errors && valueInputControl.errors['required']"
      >
        {{ required }}
      </mat-error>
    </mat-form-field>
  </div>

  <button
    mat-raised-button
    type="submit"
    color="primary"
    [disabled]="form.invalid"
  >
    {{ confirm }}
  </button>
</form>
