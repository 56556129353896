import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'dl-info-dialog',
  templateUrl: './info.dialog.html',
  styleUrls: ['./info.dialog.scss'],
})
export class InfoDialog {
  @Input()
  public content: string = '';

  @Input() public buttons: {
    label: string;
    type: string;
    color: string;
    result: string;
  }[] = [{ label: 'OK', type: 'raised', color: 'primary', result: 'OK' }];

  public constructor(private readonly _matDialogRef: MatDialogRef<any>) {}

  public close(result: string) {
    this._matDialogRef.close(result);
  }
}
