// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { NodeColumn } from './dto/node.dto';

export class NodeModel {
  static getGenerateFormList(): Promise<NodeColumn[]> {
    return wsc.execute('/ws/node/getGenerateFormList') as any;
  }
}
// 2fd522427bbb9bd396090869b2d593dd92a103c88844f237eac9e9beaef27706
