import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'dl-confirm-dialog',
  templateUrl: './confirm.dialog.html',
  styleUrls: ['./confirm.dialog.scss'],
})
export class ConfirmDialog {
  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title?: string;
      content?: string;
      ok?: string;
      cancel?: string;
    },
  ) {}
}
