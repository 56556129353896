import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'areaType' })
export class AreaTypePipe implements PipeTransform {
  public areaMap = {
    uri: $localize`連結`,
    message: $localize`說話`,
    next: $localize`下一個流程`,
    'share-message-through-promotion': $localize`分享訊息(推廣通路)`,
    'add-through-promotion': $localize`加入機器人(推廣通路)`,
  };

  public transform(value: any): any {
    if (this.areaMap[value]) {
      return this.areaMap[value];
    }

    return value;
  }
}
