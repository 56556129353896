import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AccountModel } from '@ay-gosu/server-shared';
import * as jwt from 'jwt-decode';
import { combineLatest, firstValueFrom, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  skip,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {
  asyncJob,
  AsyncJobStatusEnum,
} from '../components/async-job/async-job';
import { ConnectionService } from './connection.service';

export interface Account {
  auth?: string[];
  companyId?: number;
  iat?: number;
  accountId?: number;
  name?: string;
  token?: string;
  emailVerified?: boolean;
  roles?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public token$ = new ReplaySubject<string>(1);

  public connectedToken$ = combineLatest([
    this._connectionService.status$,
    this.token$,
  ]).pipe(
    map(([status, token]) => (status ? token : null)),
    asyncJob((token) => (token ? AccountModel.loginViaToken(token) : null)),
    asyncJob(async (job) => this._decodeToken(job.value)),
    shareReplay(1),
  );

  public accountWithoutCompany$ = this.connectedToken$.pipe(
    filter((job) => job.status === AsyncJobStatusEnum.SUCCESS),
    map((job) => job.value),
    distinctUntilChanged((prev, curr) => prev?.accountId === curr?.accountId),
  );

  public account$ = this.connectedToken$.pipe(
    filter((job) => job.status !== AsyncJobStatusEnum.LOADING),
    map((job) => {
      if (job.status === AsyncJobStatusEnum.ERROR) {
        return null;
      }
      return job.value;
    }),
    map((payload) => (payload?.companyId ? payload : null)),
    distinctUntilChanged(
      (prev, curr) =>
        prev?.accountId === curr?.accountId &&
        prev?.companyId === curr?.companyId,
    ),
  );

  private _storageTokenToLocalStorage = this.token$
    .pipe(skip(1))
    .subscribe((token) => {
      if (token) {
        window.localStorage.setItem(environment.loginTokenKey, token);
      } else {
        window.localStorage.removeItem(environment.loginTokenKey);
      }
    });

  private _applyTokeToWebsocket = this.connectedToken$.subscribe(
    async (token) => {
      try {
        await AccountModel.isLoggedIn();
      } catch (err) {
        if (token.value) {
          this.token$.next('');
        }
      }
    },
  );

  private _loginByStorageTokenPromise = this._loginByStorageToken();

  public constructor(
    private readonly _connectionService: ConnectionService,
    private readonly _router: Router,
  ) {}

  public async isLoggedIn(): Promise<boolean> {
    await this._loginByStorageTokenPromise;
    return firstValueFrom(this.account$)
      .then((res) => !!res)
      .catch((err) => false);
  }

  public async isLoggedInWithoutCompany(): Promise<boolean> {
    await this._loginByStorageTokenPromise;
    return firstValueFrom(this.accountWithoutCompany$)
      .then((res) => !!res)
      .catch((err) => false);
  }

  public async login(account: string, password: string): Promise<Account> {
    let token = await AccountModel.login(account, password);
    if (!token) throw $localize`帳號或密碼錯誤`;
    this.token$.next(token);
    return this._decodeToken(token);
  }

  public async selectCompany(companyId: number): Promise<Account> {
    if (!(await this.isLoggedInWithoutCompany())) {
      throw $localize`尚未登入，無法選擇組織`;
    }
    let token = await AccountModel.selectCompany(companyId);
    if (!token) throw $localize`選擇組織時發生錯誤`;
    this.token$.next(token);
    return this._decodeToken(token);
  }

  public async logout() {
    this._router.navigateByUrl('/login/logout');
  }

  public clearToken() {
    this.token$.next('');
  }

  private async _loginByStorageToken() {
    await this._connectionService.awaitConnected();
    let token = window.localStorage.getItem(environment.loginTokenKey);
    if (!token) this.token$.next('');
    this.token$.next(token);
  }

  private _decodeToken(token: string): Account {
    if (!token) {
      return null;
    }

    try {
      return jwt.default(token);
    } catch (err) {
      this.token$.next(null);
      console.error($localize`解碼存取權杖時發生錯誤`, { token, err });
      return null;
    }
  }
}
