import { concat, from, of, OperatorFunction } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

export function asyncJob<P, O>(
  fn: (item: P) => Promise<O>,
): OperatorFunction<P, AsyncJobStatus<O>> {
  return mergeMap((params: P) => {
    if (
      params instanceof AsyncJobStatus &&
      [AsyncJobStatusEnum.LOADING, AsyncJobStatusEnum.ERROR].includes(
        params.status,
      )
    ) {
      return of(params as any);
    }

    return concat(
      of(new AsyncJobStatus(AsyncJobStatusEnum.LOADING)),
      from(
        Promise.resolve(fn(params))
          .then(
            (value) => new AsyncJobStatus(AsyncJobStatusEnum.SUCCESS, value),
          )
          .catch(
            (error) => new AsyncJobStatus(AsyncJobStatusEnum.ERROR, error),
          ),
      ),
    );
  });
}

export enum AsyncJobStatusEnum {
  LOADING,
  SUCCESS,
  ERROR,
}

export class AsyncJobStatus<O> {
  public constructor(
    public readonly status: AsyncJobStatusEnum,
    public readonly value: O = null,
  ) {}
}
