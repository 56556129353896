export class InlineSVGConfig {
  public baseUrl?: string;
  public clientOnly?: boolean;
  public bypassHttpClientInterceptorChain?: boolean;
}

export const enum SVGScriptEvalMode {
  ALWAYS = 'always',
  ONCE = 'once',
  NEVER = 'never',
}
