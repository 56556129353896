import { Component } from '@angular/core';
import { faFacebook, faLine } from '@fortawesome/free-brands-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { ActivityRecordService } from './activity-record.service';

@Component({
  selector: 'gosu-activity-record',
  templateUrl: './activity-record.component.html',
  styleUrls: ['./activity-record.component.scss'],
})
export class ActivityRecordComponent {
  public faFacebook = faFacebook;
  public faLine = faLine;
  public faStar = faStar;

  public levels = {
    debug: $localize`除錯`,
    info: $localize`訊息`,
    warning: $localize`警告`,
    error: $localize`錯誤`,
  };

  public colors = {
    debug: '#00796B',
    info: '#0288D1',
    warning: '#F57C00',
    error: '#E64A19',
  };

  public constructor(public service: ActivityRecordService) {}
}
