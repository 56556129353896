import { Injectable } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { fromEvent, merge, of } from 'rxjs';
import {
  bufferCount,
  filter,
  map,
  mergeMap,
  shareReplay,
  take,
  tap,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class KonamiCode {
  public konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65];

  private noPassword$ = of(environment.noPassword).pipe(
    filter((noPassword) => noPassword),
  );

  private enterKonamiCode$ = fromEvent<KeyboardEvent>(window, 'keydown').pipe(
    filter((e) => e.keyCode == this.konamiCode[0]),
    mergeMap((e) =>
      fromEvent<KeyboardEvent>(window, 'keydown').pipe(
        map((e) => e.keyCode),
        bufferCount(this.konamiCode.length - 1),
        take(1),
        tap((e) => e.unshift(this.konamiCode[0])),
      ),
    ),
    filter((e) => isEqual(e, this.konamiCode)),
    take(1),
    map((e) => true),
  );

  public unlock$ = merge(this.enterKonamiCode$, this.noPassword$).pipe(
    shareReplay(1),
  );
}
